import { Upload16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { useSetAtom } from 'jotai';
import { importFileModalOpenAtom } from './atoms';
import style from './upload-file-button.module.scss';

const UploadFileButton = () => {
  const setOpen = useSetAtom(importFileModalOpenAtom);
  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <Button
      hasIconOnly
      kind="tertiary"
      size="md"
      renderIcon={Upload16}
      iconDescription="Upload"
      tooltipAlignment="center"
      tooltipPosition="top"
      onClick={toggleOpen}
      className={style.uploadButton}
    />
  );
};

export { UploadFileButton };
