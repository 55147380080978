import { createStore, atom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';
import {
  DocumentInterpretationResponse,
  DocumentResponse,
} from 'types/api-responses';

export type DocumentAtom = ReturnType<typeof useDocumentAtom>;

const refreshDocumentCounterAtom = atom(0);

const refreshDocumentCounter = atom(null, (_, set) => {
  set(refreshDocumentCounterAtom, (c) => c + 1);
});

const useDocumentAtom = (href: string) => {
  const refreshDocumentCounter = useAtomValue(refreshDocumentCounterAtom);

  return useMemo(() => {
    return createResourceAtom<DocumentResponse>(
      href,
      'keep-old-data-on-refetch'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href, refreshDocumentCounter]);
};

const useInterpretationsAtom = (href?: string) => {
  return useMemo(
    () => createResourceAtom<DocumentInterpretationResponse[]>(href),
    [href]
  );
};

const showProvisionedDocumentAtom = atomWithStorage(
  'show-interpretation-provisioned-document',
  false
);

const documentProvisionStore = createStore();

export {
  documentProvisionStore,
  showProvisionedDocumentAtom,
  useDocumentAtom,
  useInterpretationsAtom,
  refreshDocumentCounter,
};
