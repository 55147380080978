import { createResourceAtom } from 'lib/atoms';
import { useMemo } from 'react';
import { WellsResponse } from 'types/api-responses';

const useWellAtom = (href: string, versionId?: string) => {
  const URL = versionId ? `${href}?version_id=${versionId}` : `${href}`;
  return useMemo(
    () => createResourceAtom<WellsResponse>(URL),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [href, versionId]
  );
};

export { useWellAtom };
