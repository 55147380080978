import {
  Modal,
  FileUploaderDropContainer,
  FileUploaderItem,
  FormGroup,
  RadioButtonGroup,
  RadioButton,
  InlineNotification,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  DataTableSkeleton,
} from 'carbon-components-react';
import { Section } from 'components/section';
import { DocumentImport16 } from '@carbon/icons-react';
import { Link } from 'components/link';
import { ReactNode, useState } from 'react';
import { EmptyState } from 'components/empty-state';
import DocumentSvg from 'images/srp-illustration-document.svg';
import { DestroyConfirmation } from 'components/destroy-confirmation';
import { useAtom, useSetAtom } from 'jotai';
import {
  createSupportingFileAtom,
  fileAtom,
  importFileModalOpenAtom,
  useDeleteFileAtom,
  useSupportingFilesAtom,
} from './atoms';
import { WellSupportingFileResponse } from 'types/api-responses';
import { TrashButton } from 'components/trash-button';
import style from './supporting-files.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  supportingFilesHref: string;
  getSupportingFiles: () => Promise<void>;
}

const ImportModal = ({
  open,
  onClose,
  supportingFilesHref,
  getSupportingFiles,
}: Props) => {
  const [fileType, setFileType] = useState<string | number>('');
  const [upload, setUpload] = useAtom(fileAtom);
  const [result, createFile] = useAtom(createSupportingFileAtom);
  const [errors, setErrors] = useState<string[]>([]);

  const resetAndClose = () => {
    setUpload(undefined);
    setErrors([]);
    setFileType('');
    onClose();
  };

  return (
    <Modal
      modalHeading="Upload File"
      primaryButtonText="Import"
      secondaryButtonText="Cancel"
      size="md"
      className={style.supportingFilesModal}
      onRequestClose={() => {
        resetAndClose();
      }}
      onRequestSubmit={() => {
        if (!supportingFilesHref || !fileType || !upload.file) return;
        createFile({
          url: supportingFilesHref,
          type: 'POST',
          data: {
            fileType: String(fileType),
            attachment: upload.file,
          },
          onSuccess: () => {
            getSupportingFiles();
            resetAndClose();
          },
          onError: (error) => {
            if (error?.errors) {
              const allErrors: string[] = Object.values(error.errors).reduce(
                (acc, el) => acc.concat(el),
                []
              );
              setErrors(allErrors);
            }
          },
        });
      }}
      primaryButtonDisabled={!upload.file || !fileType || result.loading}
      open={open}
    >
      <FormGroup legendText="File Type">
        <RadioButtonGroup
          name="type"
          onChange={(value) => setFileType(value)}
          valueSelected={fileType}
        >
          <RadioButton labelText="Internal" value="internal" />
          <RadioButton labelText="Regulatory" value="regulatory" />
        </RadioButtonGroup>
      </FormGroup>

      <fieldset>
        <p>Max file size is 500 MB</p>
      </fieldset>
      <FileUploaderDropContainer
        labelText="Drag and drop files here or click to upload"
        onAddFiles={(_event, { addedFiles }) => {
          setUpload(addedFiles[0]);
        }}
        disabled={!!upload.file}
      />
      {upload.file && (
        <FileUploaderItem
          name={upload.name}
          status={upload.status}
          invalid={upload.invalid}
          onDelete={() => setUpload(undefined)}
        />
      )}
      {!!errors.length && (
        <InlineNotification
          kind={'error'}
          lowContrast
          title={'Error!'}
          subtitle={`${errors.join(', ')}.`}
        />
      )}
    </Modal>
  );
};

const FileList = ({ children }: { children: ReactNode }) => {
  return (
    <TableContainer>
      <Table size="md" className={style.fileTable}>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Owner</TableHeader>
            <TableHeader>File type</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

const File = ({
  file,
  onRemoveClick,
}: {
  file: WellSupportingFileResponse;
  onRemoveClick: () => void;
}) => {
  return (
    <TableRow>
      <TableCell>
        <Link
          truncate
          to={file.attachmentHref}
          target="_blank"
          rel="noreferrer"
        >
          {file.fileName}
        </Link>
      </TableCell>
      <TableCell>{file.createdBy}</TableCell>
      <TableCell>{file.fileType}</TableCell>
      <TableCell align="right">
        <TrashButton
          kind="ghost"
          description="Remove"
          size="sm"
          tooltipPosition="left"
          onClick={onRemoveClick}
        />
      </TableCell>
    </TableRow>
  );
};

const SupportingFilesImpl = ({
  files,
  supportingFilesHref,
  getSupportingFiles,
}: {
  files: WellSupportingFileResponse[] | undefined;
  supportingFilesHref: string;
  getSupportingFiles: () => Promise<void>;
}) => {
  const [open, setOpen] = useAtom(importFileModalOpenAtom);
  const toggleOpen = () => setOpen((prev) => !prev);
  const [fileToDeleteUri, setFileToDeleteUri] = useState('');
  const deleteFileAtom = useDeleteFileAtom(getSupportingFiles);
  const deleteFile = useSetAtom(deleteFileAtom);
  return (
    <div className={style.supportingFiles}>
      <DestroyConfirmation
        open={!!fileToDeleteUri}
        onRequestSubmit={() => {
          deleteFile(fileToDeleteUri);
          setFileToDeleteUri('');
        }}
        onRequestClose={() => {
          setFileToDeleteUri('');
        }}
      />
      <ImportModal
        open={open}
        onClose={toggleOpen}
        supportingFilesHref={supportingFilesHref}
        getSupportingFiles={getSupportingFiles}
      />
      <div className={style.btnContainer}>
        <Button
          kind="ghost"
          size="md"
          renderIcon={DocumentImport16}
          onClick={toggleOpen}
        >
          Import Files
        </Button>
      </div>
      <div className={style.filesContainer}>
        {files ? (
          files.length ? (
            <FileList>
              {files.map((file) => (
                <File
                  key={file.id}
                  file={file}
                  onRemoveClick={() => setFileToDeleteUri(file.href)}
                />
              ))}
            </FileList>
          ) : (
            <EmptyState
              icon={DocumentSvg}
              headerText="No Files Available"
              helperText="Currently this well doesn't have any supporting files."
            />
          )
        ) : (
          <DataTableSkeleton
            showHeader={false}
            showToolbar={false}
            columnCount={4}
          />
        )}
      </div>
    </div>
  );
};

const SupportingFiles = ({ href }: { href: string }) => {
  const supportingFilesAtom = useSupportingFilesAtom(href);
  const [supportingFiles, getSupportingFiles] = useAtom(supportingFilesAtom);

  if (supportingFiles.data && 'error' in supportingFiles.data)
    return (
      <InlineNotification
        kind="error"
        title={supportingFiles.data.error}
        lowContrast
      />
    );

  return (
    <Section
      header={
        <>
          <span>{`Related Well Files (${supportingFiles.data?.length})`}</span>
          <span className={style.noVersionMarker}>Not Versioned</span>
        </>
      }
    >
      <SupportingFilesImpl
        files={supportingFiles.data}
        supportingFilesHref={href}
        getSupportingFiles={getSupportingFiles}
      />
    </Section>
  );
};

export { SupportingFiles };
