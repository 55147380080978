import { useAtom } from 'jotai';
import { Checkbox } from 'carbon-components-react';
import { wellScopedUnitLayerVisibility } from './atoms';
import style from 'components/map/map.module.scss';
import { useEffect } from 'react';

// Represents a checkbox to select/unselect a map layer for scoped layers on wells page.
export const ScopedWellUnitLayerSelection = () => {
  const [wellScopedUnitLayer, setWellScopedUnitLayer] = useAtom(
    wellScopedUnitLayerVisibility
  );
  useEffect(() => {
    // Temporarily setting wellScopedUnitLayer to false on mount to always keep checkbox unchecked and disabled
    setWellScopedUnitLayer(false);
  }, [setWellScopedUnitLayer]);

  return (
    <div className={style.groupChecks}>
      <div className={style.scopedUnits}>
        <Checkbox
          id="scoped-unit-layer"
          labelText="Units"
          disabled
          checked={wellScopedUnitLayer}
          onChange={(checked: boolean) => {
            setWellScopedUnitLayer(checked);
          }}
        />
      </div>
    </div>
  );
};
