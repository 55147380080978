import { WatsonHealthRotate_36016 as ResetIcon } from '@carbon/icons-react';
import { Button, MultiSelect } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';
import style from './version-dropdown.module.scss';

export interface DropdownItem {
  id: string;
  label: string;
  versionId: string;
}

interface DropdownProps {
  dropdownItems: DropdownItem[];
  selectedVersionIds: string[];
  onReset: () => void;
  onChange: (selectedIds: string[]) => void;
}

export const VersionDropdown: React.FC<DropdownProps> = ({
  dropdownItems,
  onReset,

  selectedVersionIds,
  onChange,
}) => {
  const [dropdownKey, setDropdownKey] = useState(0);

  const selectedItems = dropdownItems.filter((item) =>
    selectedVersionIds.includes(item.id)
  );

  // Initialize selection without triggering parent's useEffect
  const initializeSelection = () => {
    const currentItem = dropdownItems.find(
      (item) => item.versionId === 'current'
    );
    if (currentItem) {
      onChange(['current']);
    }
  };

  // Call the initialization logic directly
  useEffect(() => {
    initializeSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReset = () => {
    initializeSelection(); // Reset to initial state
    setDropdownKey((prevKey) => prevKey + 1); // Force remount
    onReset();
  };

  const handleOnChange = (event: { selectedItems: DropdownItem[] }) => {
    const selected = event.selectedItems;
    const ids = selected.map((item) => item.id);
    onChange(ids); // Notify parent with selected items
  };

  const updatedDropdownItems = dropdownItems.map((item) => ({
    ...item,
    disabled:
      selectedItems.length >= 5 &&
      !selectedItems.some((si) => si.id === item.id),
  }));

  const dropdownLabel =
    selectedItems.length === 0
      ? 'Please select a version'
      : selectedItems.length === 1
      ? selectedItems[0]?.label
      : 'Selected Versions';

  return (
    <div className={style.versionDropdown}>
      <div className={style.dropDown} data-testid="version-dropdown">
        <MultiSelect
          key={dropdownKey}
          size="lg"
          id="version-multiselect"
          label={dropdownLabel}
          items={updatedDropdownItems}
          itemToString={(item) => `${item.label}`}
          initialSelectedItems={
            dropdownItems.length > 0
              ? updatedDropdownItems.filter(
                  (item) => item.versionId === 'current'
                )
              : []
          }
          onChange={handleOnChange}
          selectionFeedback="fixed"
          sortItems={(items) => [...items]}
        />
      </div>
      <div className={style.actions}>
        <Button
          kind="tertiary"
          onClick={handleReset}
          className={style.buttonContent}
        >
          <span>Reset to Current</span>
          <ResetIcon className={style.icon} />
        </Button>
      </div>
    </div>
  );
};
