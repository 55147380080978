import {
  Column,
  DataTableSkeleton,
  Grid,
  InlineNotification,
  Row,
  SkeletonPlaceholder,
  Tile,
} from 'carbon-components-react';
import { useAtomValue } from 'jotai';
import { useUnitTableAtom, useWellFeaturesAtom } from './atoms';
import { Section } from 'components/section';
import { Map, Well } from 'components/map';
import { FormationAndDepth } from './formation-and-depth';
import { WellAnalysis } from './production';
import { SupportingFiles } from './supporting-files';
import NoMapSvg from 'images/srp-illustration-no-map.svg';
import { EmptyState } from 'components/empty-state';
import { getMapDefaultConfig } from 'components/map/atoms';
import { atomWithStorage } from 'jotai/utils';
import { WellsResponse } from 'types/api-responses';
import { ProvideRootResource } from 'components/hydrate-atoms';
import style from './well-page-content.module.scss';
import UnitTable from './unit-details-table';
import DevAreaSvg from 'images/srp-development-areas.svg';
import { ScopedWellUnitLayerSelection } from './well-scoped-unit-layer-section';
import { SkeletonTextRows } from 'components/skeleton-text-rows';
import { useWellAnalysisHrefs } from './hooks';
import { CumulativeProduction } from 'components/comulative-production';
import { WellDetailsContent } from './well-details';

const UnitsTableSection = ({ href }: { href: string }) => {
  const unitsTableAtom = useUnitTableAtom(href);
  const unitsTableData = useAtomValue(unitsTableAtom);

  if (unitsTableData.loading) {
    return (
      <DataTableSkeleton
        showHeader={false}
        showToolbar={false}
        columnCount={7}
        rowCount={5}
      />
    );
  }

  if (!unitsTableData.data) {
    return null;
  }

  if ('error' in unitsTableData.data) {
    return (
      <InlineNotification
        kind="error"
        title="Notification"
        lowContrast
        subtitle={'Error loading units table, Please try again'}
      />
    );
  }

  if (!unitsTableData.data.length) {
    return (
      <EmptyState
        icon={DevAreaSvg}
        headerText="No unit details available for this well"
      />
    );
  }

  return <UnitTable data={unitsTableData.data} />;
};

const WellPageContent = ({
  well,
  hideMap,
}: {
  well?: WellsResponse;
  hideMap?: boolean;
}) => {
  const wellAnalysisHrefs = useWellAnalysisHrefs(well);

  const gridProps = hideMap ? {} : { md: 12, lg: 10 };
  return (
    <Grid className="bx--no-gutter" fullWidth condensed>
      <Row className={style.mapWellRow}>
        <Column {...gridProps} className={style.colDetails}>
          <div className={style.wrapper}>
            <Tile className={style.rowBlock}>
              <Section header="Well Details">
                {well ? (
                  <WellDetailsContent well={well} />
                ) : (
                  <div data-testid="well-details-skeleton">
                    <SkeletonTextRows rows={3}></SkeletonTextRows>
                  </div>
                )}
              </Section>
            </Tile>
            <Tile className={style.rowBlock}>
              <Section header="Unit Details">
                {/* Temporarily replacing UnitsTableSection with InlineNotification */}
                <InlineNotification
                  kind="info"
                  title="Unit details not available at this time."
                  lowContrast
                />
                {/* <UnitsTableSection href={well.resources.units.href} /> */}
              </Section>
            </Tile>
            <Tile>
              <Section header="Formation & Depth">
                {well ? (
                  <FormationAndDepth well={well} />
                ) : (
                  <SkeletonPlaceholder
                    data-testid="formations-table-placeholder"
                    className={style.formationsTablePlaceholder}
                  />
                )}
              </Section>
            </Tile>
          </div>
        </Column>
        {!hideMap && (
          <Column md={12} lg={6} className={style.colMap}>
            <Tile className={style.mapTile}>
              <Section header="Map">
                <ProvideRootResource>
                  {well ? (
                    <WellMap featuresHref={well.resources.features.href} />
                  ) : (
                    <SkeletonPlaceholder
                      data-testid="well-map-skeleton"
                      className={style.mapLoad}
                    />
                  )}
                </ProvideRootResource>
              </Section>
            </Tile>
          </Column>
        )}
      </Row>
      <Row>
        <Column>
          <Tile className={style.rowBlock}>
            <Section header="Cumulative Production">
              {well ? (
                <CumulativeProduction
                  production={{
                    totalLiquid: well.totalLiquid,
                    totalGas: well.totalGas,
                    totalWater: well.totalWater,
                  }}
                  monthsFromFirstToLastProduction={
                    well.monthsFromFirstToLastProduction
                  }
                  monthsReported={well.monthsReported}
                  cumulativeHorizontalPos
                />
              ) : (
                <div data-testid="cumulative-production-skeleton">
                  <SkeletonTextRows rows={5}></SkeletonTextRows>
                </div>
              )}
            </Section>
          </Tile>
        </Column>
      </Row>
      <Row>
        <Column>
          <Tile className={style.rowBlock}>
            <Section header="Well Analysis">
              {wellAnalysisHrefs ? (
                <WellAnalysis
                  productionForecastsVersionsHref={
                    wellAnalysisHrefs.productionForecastsVersionsHref
                  }
                  productionRecordsVersionsHref={
                    wellAnalysisHrefs.productionRecordsVersionsHref
                  }
                  productionForecastsHref={
                    wellAnalysisHrefs.productionForecastsHref
                  }
                  productionRecordsHref={
                    wellAnalysisHrefs.productionRecordsHref
                  }
                />
              ) : null}
            </Section>
          </Tile>
        </Column>
      </Row>
      <Row>
        <Column>
          <Tile className={style.rowBlock}>
            {well ? (
              <SupportingFiles href={well.resources.supportingFiles.href} />
            ) : (
              <SkeletonTextRows rows={5}></SkeletonTextRows>
            )}
          </Tile>
        </Column>
      </Row>
    </Grid>
  );
};

const mapConfigAtom = atomWithStorage(
  'insights-wells-show-map-config',
  getMapDefaultConfig({
    wells: {
      checked: true,
      options: {
        surfaceHole: true,
        bottomHole: true,
        stick: true,
        survey: true,
        vertical: true,
      },
    },
  })
);

const WellMap = ({
  featuresHref,
}: {
  featuresHref: string;
  scopedUnitHref?: string;
}) => {
  const wellFeaturesAtom = useWellFeaturesAtom(featuresHref);
  const features = useAtomValue(wellFeaturesAtom);
  if (features.loading)
    return <SkeletonPlaceholder className={style.mapLoad} />;
  if (features.data && 'error' in features.data)
    return (
      <InlineNotification
        kind="error"
        title={features.data.error}
        lowContrast
      />
    );

  let latitude = 0;
  let longitude = 0;
  if (features.data?.surfaceHole) {
    [latitude, longitude] = features.data.surfaceHole.coordinates;
  } else {
    return (
      <EmptyState
        icon={NoMapSvg}
        iconClassName={style.emptyMapIcon}
        headerText="No Data Available"
        helperText="Currently Insights doesn’t have enough data to map this well."
      />
    );
  }

  return (
    <Map
      latitude={longitude}
      longitude={latitude}
      showControls
      showMapFilters
      className={style.wellsMap}
      disableWellsLayer
      mapConfigAtom={mapConfigAtom}
      scopedLayers={<ScopedWellUnitLayerSelection />}
    >
      <>
        {/* <ScopedWellUnitLayer source={scopedUnitHref} />*/}
        <Well
          id="well"
          features={features.data}
          mapConfigAtom={mapConfigAtom}
        />
      </>
    </Map>
  );
};

export { WellPageContent, UnitsTableSection };
