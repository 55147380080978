import { SkeletonText } from 'carbon-components-react';

interface Props {
  rows: number;
}

const SkeletonTextRows = ({ rows }: Props) => {
  return (
    <>
      {[...Array(rows)].map((_el, i) => (
        <SkeletonText key={`skeleton-text-${i}`} />
      ))}
    </>
  );
};

export { SkeletonTextRows };
