import { useMemo } from 'react';
import { createResourceAtom } from 'lib/atoms';

import { CompletionConfigurationResponse } from 'types/api-responses';

export const useConfigurationOptionsAtom = (href?: string) =>
  useMemo(
    () => createResourceAtom<CompletionConfigurationResponse>(href),
    [href]
  );

export function getFormDefaults(
  configurationOptions: CompletionConfigurationResponse
) {
  const extractionConfigurations =
    configurationOptions.extractionConfigurations.filter((el) => el.default)[0];
  const promptConfigurations = configurationOptions.promptConfigurations.filter(
    (el) => el.default
  )[0];
  const completionConfigurations =
    configurationOptions.completionConfigurations.filter((el) => el.default)[0];

  return {
    extractionConfigurations,
    promptConfigurations,
    completionConfigurations,
  };
}
