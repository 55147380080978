import { memo, useRef } from 'react';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import { zip } from 'lodash';
import { ProductionForecastResponse } from 'types/api-responses';
import styles from './production-graph.module.scss';
import classNames from 'classnames';
import Circle from 'images/value-circle-symbol.svg';
import Diamond from 'images/value-diamond-symbol.svg';
import Square from 'images/value-square-symbol.svg';
import Triangle from 'images/value-triange-symbol.svg';
import TriangleDown from 'images/value-triangle-down-symbol.svg';
import moment from 'moment';
import {
  Legend,
  defaultSelectedLegend,
} from 'components/well-page-content/production';

interface Props {
  graphData: Array<{
    versionId: string;
    version: string;
    dates: NonNullable<ProductionForecastResponse['dates']>;
    liquid: ProductionForecastResponse['liquid'];
    water: ProductionForecastResponse['water'];
    gas: ProductionForecastResponse['gas'];
  }>;
  className?: string;
  selectedLegendVersion?: string;
  setSelectedLegendVersion?: (version: string | undefined) => void;
  staggeredSymbol?: boolean;
  selectedLegend: Legend[];
  setSelectedLegend: (legend: Legend[]) => void;
}

function maskZeros(n: number) {
  return n === 0 ? 0.001 : n;
}

// Version symbols for the graph.
const graphSymbols: Highcharts.SymbolKeyValue[] = [
  'circle',
  'diamond',
  'square',
  'triangle',
  'triangle-down',
];

const tooltipSymbols = ['\u25CF', '\u25C6', '\u25FC', '\u25B2', '\u25BC'];

// Transforms raw date and data arrays into formatted points for Highcharts plotting.
// Markers are shown every 12 points, offset by versionIndex.
// Zero values are masked to 0.001 to work with logarithmic scale.
function plotPoints(
  parsedDates: number[],
  versionIndex: number,
  data?: number[],
  isMarkerVisible: boolean = true,
  staggeredSymbol: boolean = false
) {
  return zip(parsedDates, data?.map(maskZeros)).map(
    ([date, dataVal], index) => {
      return {
        x: date,
        y: dataVal,
        marker: {
          symbol: graphSymbols[versionIndex],
          enabled:
            isMarkerVisible &&
            (staggeredSymbol
              ? (index - versionIndex * 2) % 24 === versionIndex
              : true),
          radius: 5,
        },
      };
    }
  );
}

// Extract version number for sorting
// extractVersionNumber("Version 5")  // returns 5
function extractVersionNumber(version: string) {
  return parseInt(version.match(/\d+/)?.[0] || '1');
}

// This function sorts an array of data objects based on their version numbers
function sortDataByVersion(data: Props['graphData']) {
  return [...data].sort((a, b) => {
    const versionA = extractVersionNumber(a.version);
    const versionB = extractVersionNumber(b.version);
    return versionA - versionB;
  });
}

// Function to determine Y-axis label based on selected legends
const getYAxisLabel = (selected: Legend[]) => {
  const hasBBL = selected.some((item) => ['liquid', 'water'].includes(item));
  const hasMCF = selected.includes('gas');

  if (hasBBL && hasMCF) return 'BBL & MCF';
  if (hasBBL) return 'BBL';
  if (hasMCF) return 'MCF';
  return ''; // Defaulting label to empty when nothing is selected
};

export const ProductionGraph = memo(
  ({
    className,
    graphData,
    setSelectedLegendVersion,
    selectedLegendVersion,
    staggeredSymbol = false,
    selectedLegend,
    setSelectedLegend,
  }: Props) => {
    // because Highchart is stateful keeping a reference to Highchart to manipulate the graph.
    const chartRef = useRef<{ chart: Highcharts.Chart }>(null);
    const sortedGraphData = sortDataByVersion(graphData);

    // SVGs that represent the different symbols for the versions.
    const versionLegends = [Circle, Diamond, Square, Triangle, TriangleDown];

    // If there is only one version, emphasise it by default in the legend and not allow the user to toggle it.
    const initialSelectedVersion =
      sortedGraphData.length > 1 ? undefined : sortedGraphData[0].version;

    // When the version legend is clicked it toggles the visibility of the value line
    const legendItemClick = function (legend: Legend) {
      const isSelected = selectedLegend.includes(legend);
      const newSelectedLegend = isSelected
        ? selectedLegend.filter((l) => l !== legend)
        : [...selectedLegend, legend];
      setSelectedLegend([...newSelectedLegend]);

      if (chartRef.current) {
        chartRef.current.chart.series.forEach((series) => {
          if (series.name === legend) {
            isSelected ? series.hide() : series.show();
          }
        });
      }
    };

    const isMarkerVisible = sortedGraphData.length > 1;
    const series = sortedGraphData.map((data, index) => {
      const { dates, liquid, water, gas } = data;
      // HC expects dates in UTC. Passing dates in UTC prevents time jump.
      const parsedDates = dates.map((date) => moment.utc(date).valueOf());
      return [
        {
          version: data.versionId,
          type: 'spline',
          name: 'liquid',
          color: '#42be65',
          tooltip: { valueSuffix: ' BBL' },
          data: plotPoints(
            parsedDates,
            index,
            liquid,
            isMarkerVisible,
            staggeredSymbol
          ),
          showInLegend: false,
          visible: selectedLegend.includes('liquid'),
        },
        {
          version: data.versionId,
          type: 'spline',
          name: 'water',
          color: '#4589ff',
          tooltip: { valueSuffix: ' BBL' },
          data: plotPoints(
            parsedDates,
            index,
            water,
            isMarkerVisible,
            staggeredSymbol
          ),
          showInLegend: false,
          visible: selectedLegend.includes('water'),
        },
        {
          version: data.versionId,
          type: 'spline',
          name: 'gas',
          color: '#fa4d56',
          tooltip: { valueSuffix: ' MCF' },
          data: plotPoints(
            parsedDates,
            index,
            gas,
            isMarkerVisible,
            staggeredSymbol
          ),
          showInLegend: false,
          visible: selectedLegend.includes('gas'),
        },
      ];
    });

    return (
      <>
        <div className={className} data-testid="production-graph">
          <Chart
            ref={chartRef}
            highcharts={Highcharts}
            options={{
              title: 'Production',
              chart: {
                type: 'spline',
                height: 600,
                spacingBottom: 20,
                zoomType: 'x',
                style: {
                  fontFamily: 'IBM Plex Sans',
                  color: '#6f6f6f',
                },
              },
              xAxis: {
                type: 'datetime',
                gridLineWidth: 1,
                title: { text: 'Time' },
                dateTimeLabelFormats: { millisecond: "%b '%y" },
              },
              yAxis: {
                type: 'logarithmic',
                minorTickInterval: 0.1,
                title: { text: getYAxisLabel(selectedLegend) },
                min: 1,
              },
              tooltip: {
                shared: true,
                xDateFormat: '%B %Y',
                valueDecimals: 0,
                // Formats the tooltip to show the value of the selected version.
                // Shows the tooltip only for the selected version.
                formatter: function (
                  this: Highcharts.TooltipFormatterContextObject
                ) {
                  if (!this.points) return false;
                  const filteredPoints = this.points.filter(
                    (point) =>
                      (point.series.options as any).version ===
                      selectedLegendVersion
                  );
                  if (filteredPoints.length === 0) return false;
                  // @ts-expect-error preexisting HC implementation had weak typing
                  const date = Highcharts.dateFormat('%B %Y', this.x);
                  const versionIndex = sortedGraphData.findIndex(
                    (data) => data.versionId === selectedLegendVersion
                  );
                  const pointsHtml = filteredPoints
                    .map((point) => {
                      return `<br/><span style="color:${point.series.color}">${
                        tooltipSymbols[versionIndex]
                      }</span> ${
                        point.series.name
                        // @ts-expect-error preexisting HC implementation had weak typing
                      }: <b>${Highcharts.numberFormat(point.y, 0)}${
                        // @ts-expect-error preexisting HC implementation had weak typing
                        point.series.tooltipOptions.valueSuffix
                      }</b>`;
                    })
                    .join('');
                  return `<b>${date}</b>${pointsHtml}`;
                },
              },
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
              },
              plotOptions: {
                series: {
                  animation: {
                    duration: 3000,
                  },
                  lineWidth: 2,
                },
              },
              series: series.flat(),
              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 1100,
                    },
                    chartOptions: {
                      legend: {
                        align: 'left',
                        verticalAlign: 'bottom',
                        layout: 'horizontal',
                      },
                    },
                  },
                ],
              },
            }}
          />
        </div>
        <div className={styles.legendContainer}>
          <div className={styles.valueLegendContainer}>
            {defaultSelectedLegend.map((legend) => (
              <div
                key={legend}
                className={styles.valueLegendItem}
                onClick={() => legendItemClick(legend)}
              >
                <div
                  className={classNames(styles.valueLegend, styles[legend], {
                    [styles.unselected]: !selectedLegend.includes(legend),
                  })}
                />
                <span
                  className={classNames(styles.label, {
                    [styles.lineThrough]: !selectedLegend.includes(legend),
                  })}
                >
                  {legend}
                </span>
              </div>
            ))}
          </div>
          <div className={styles.versionLegendContainer}>
            {sortedGraphData.map((data, index) => {
              const isSelected = selectedLegendVersion === data.versionId;
              return (
                <div
                  className={styles.versionLegend}
                  key={index}
                  data-testid="version-legend-container"
                  // When the version legend is clicked it highlights the value line for the selected version.
                  onClick={() => {
                    if (initialSelectedVersion) {
                      return;
                    }
                    setSelectedLegendVersion?.(
                      isSelected ? undefined : data.versionId
                    );
                    if (chartRef.current) {
                      chartRef.current.chart.series.forEach((serie) => {
                        // @ts-expect-error preexisting HC implementation had weak typing
                        if (serie.options.version === data.versionId) {
                          // @ts-expect-error preexisting HC implementation had weak typing
                          serie.update({ lineWidth: isSelected ? 2 : 6 });
                        } else {
                          // @ts-expect-error preexisting HC implementation had weak typing
                          serie.update({ lineWidth: 2 });
                        }
                      });
                    }
                  }}
                >
                  {
                    <img
                      className={classNames({
                        [styles.selectedLegend]:
                          isSelected && !initialSelectedVersion,
                      })}
                      src={versionLegends[index]}
                    ></img>
                  }
                  <span
                    className={classNames(styles.label, {
                      [styles.selectedLabel]:
                        isSelected && !initialSelectedVersion,
                    })}
                  >
                    {data.version}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
);

ProductionGraph.displayName = 'ProductionGraph';
