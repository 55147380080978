import { atom, useStore } from 'jotai';
import { loadable } from 'jotai/utils';
import { createResourceAtom } from 'lib/atoms';
import request from 'lib/request';
import { JSONObject } from 'lib/types';
import { useEffect, useMemo } from 'react';
import {
  CompletionConfigurationOption,
  CompletionConfigurationResponse,
  CompletionItemResponse,
  CompletionPromptConfigurationOption,
  OCRExtractionResponse,
  ProvisionResponse,
} from 'types/api-responses';
import type { FeatureFlagWindow } from 'types/window';

declare let window: FeatureFlagWindow;

export const provisionsHrefAtom = atom<string | undefined>(undefined);
export const provisionHrefAtom = atom<string | undefined>(undefined);
export const provisionListAtom = atom<ProvisionResponse[]>([]);
export const currentProvisionIdAtom = atom<string | number | undefined>(
  undefined
);

const provisionsAtomBase = atom<
  Promise<ProvisionResponse[] | { error: string } | undefined>
>(async (get) => {
  get(refreshProvisionsCounterAtom);
  const href = get(provisionsHrefAtom);
  if (!href) return undefined;

  let documentId;
  const match = href.match(/\/documents\/(\d+)\/completions/);
  if (match) {
    documentId = match[1];
  }

  try {
    const llmProvisions: ProvisionResponse[] = (await request.get(
      href
    )) as ProvisionResponse[];

    // Only fetch composite provisions if feature flag is enabled
    if (window.CONFIG?.featureFlags.autoprovisionerV2) {
      if (!llmProvisions.length || !documentId) {
        return llmProvisions;
      }

      const latestProvision = llmProvisions[0];
      const compositeContent: JSONObject = await request.get(
        `/provisions/${documentId}?document_extraction_completion_id=${latestProvision.id}`
      );
      if (compositeContent.error) {
        return llmProvisions;
      }

      const compositeProvision: ProvisionResponse = {
        ...latestProvision,
        id: compositeContent?.id as string,
        content: compositeContent,
        metadata: {
          ...latestProvision.metadata,
          completion: {
            ...latestProvision.metadata.completion,
            service: 'composite',
          },
        },
      };
      return [compositeProvision, ...llmProvisions];
    }

    // If feature flag is off, return just the LLM provisions
    return llmProvisions;
  } catch (error) {
    return { error: 'Failed to fetch provisions' };
  }
});

const refreshProvisionCounterAtom = atom(0);

export const refreshProvisionsCounterAtom = atom(0);
export const refreshProvisionsCounter = atom(null, (_, set) => {
  set(refreshProvisionsCounterAtom, (prev) => {
    return prev + 1;
  });
});

const provisionAtomBase = atom<
  Promise<ProvisionResponse | { error: string } | undefined>
>(async (get) => {
  const href = get(provisionHrefAtom);
  const currentProvisionId = get(currentProvisionIdAtom);
  const provisions = get(provisionsAtom);
  get(refreshProvisionCounterAtom);

  if (!href) return undefined;

  try {
    if (
      provisions.state === 'hasData' &&
      provisions.data &&
      !('error' in provisions.data)
    ) {
      if (!currentProvisionId && provisions.data.length > 0) {
        return provisions.data[0];
      }

      // Only handle composite provisions if feature flag is enabled
      if (window.CONFIG?.featureFlags.autoprovisionerV2) {
        const existingProvision = provisions.data.find(
          (p) => p.id === currentProvisionId
        );

        if (existingProvision?.metadata?.completion?.service === 'composite') {
          const latestProvision: ProvisionResponse = (await request.get(
            provisions.data[1].href
          )) as ProvisionResponse;

          const compositeProvision: ProvisionResponse = {
            ...latestProvision,
            id: existingProvision?.id as string,
            content: existingProvision.content,
            metadata: {
              ...latestProvision.metadata,
              completion: {
                ...latestProvision.metadata.completion,
                service: 'composite',
              },
            },
          };
          return compositeProvision;
        }
      }
    }

    return (await request.get(href)) as ProvisionResponse;
  } catch (error) {
    return { error: 'Failed to fetch provision' };
  }
});

export const provisionsAtom = loadable(provisionsAtomBase);
export const provisionAtom = loadable(provisionAtomBase);
export const refreshProvisionCounter = atom(null, (_, set) => {
  set(refreshProvisionCounterAtom, (prev) => prev + 1);
});

export const useOCRExtractionAtom = (href?: string) =>
  useMemo(() => createResourceAtom<OCRExtractionResponse>(href), [href]);

export const useCompletionItems = (href?: string) =>
  useMemo(() => createResourceAtom<CompletionItemResponse[]>(href), [href]);

export const useConfigurationOptionsAtom = (href?: string) =>
  useMemo(
    () => createResourceAtom<CompletionConfigurationResponse>(href),
    [href]
  );

export function getFormDefaults(
  configurationOptions: CompletionConfigurationResponse
) {
  const extractionConfigurations =
    configurationOptions.extractionConfigurations.filter((el) => el.default)[0];
  const promptConfigurations = configurationOptions.promptConfigurations.filter(
    (el) => el.default
  )[0];
  const completionConfigurations =
    configurationOptions.completionConfigurations.filter((el) => el.default)[0];

  return {
    extractionConfigurations,
    promptConfigurations,
    completionConfigurations,
  };
}

export function getPayload({
  extractionConfiguration,
  promptConfiguration,
  completionConfiguration,
}: {
  extractionConfiguration: CompletionConfigurationOption;
  promptConfiguration: CompletionPromptConfigurationOption;
  completionConfiguration: CompletionConfigurationOption;
}) {
  return {
    extractionConfiguration: {
      provider: extractionConfiguration.provider,
      version: extractionConfiguration.version,
    },
    promptConfiguration: { version: promptConfiguration.version },
    completionConfiguration: {
      provider: completionConfiguration.provider,
      version: completionConfiguration.version,
    },
  };
}

export function useClearStore() {
  const store = useStore();
  useEffect(() => {
    return () => {
      store.set(provisionsHrefAtom, undefined);
      store.set(provisionHrefAtom, undefined);
      store.set(currentProvisionIdAtom, undefined);
      store.set(provisionListAtom, []);
    };
  }, [store]);
}

export function isCompositeProvision(provision: ProvisionResponse): boolean {
  return provision.metadata.completion.service === 'composite';
}
