import { Modal } from 'carbon-components-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DocumentResponse } from 'types/api-responses';
import {
  documentAtom,
  useDocumentEditFormAtom,
  useRunDocumentUpdateAtom,
} from './atoms';
import { useBodyClass } from 'lib/hooks/useBodyClass';
import { EditDocumentForm } from './edit-document-form';
import { ProvideRootResource } from 'components/hydrate-atoms';
import style from './edit-document-modal.module.scss';
import { useMemo } from 'react';
import { getFormIsInvalidAtom } from 'atoms/form-atoms';

interface Props {
  document: DocumentResponse;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const EditDocumentModalImplementation = (props: Props) => {
  const { document, isOpen, onSave, onClose } = props;
  const formAtom = useDocumentEditFormAtom(document);
  const form = useAtomValue(formAtom);
  const updateDocumentAtom = useRunDocumentUpdateAtom(document.href, form);
  const [updating, updateDocument] = useAtom(updateDocumentAtom);
  const setDocument = useSetAtom(documentAtom);
  const invalidFormAtom = useMemo(
    () => getFormIsInvalidAtom(formAtom),
    [formAtom]
  );
  const invalidForm = useAtomValue(invalidFormAtom);
  useBodyClass('edit-document-modal-open', isOpen);

  if (!form) return null;

  return (
    <Modal
      open={isOpen}
      size="md"
      className={style.editDocumentModal}
      modalHeading="Edit Document"
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryButtonDisabled={
        document.type === 'Document::NonCourthouse'
          ? updating.loading
          : invalidForm || updating.loading
      }
      onRequestSubmit={() =>
        updateDocument(() => {
          onSave();
          onClose();
        })
      }
      onRequestClose={() => {
        // Setting the doc here triggers the atom
        // that initializes the form with the original
        // document
        setDocument({ ...document });
        onClose();
      }}
    >
      {isOpen && (
        <EditDocumentForm
          form={form}
          type={document.type}
          county={document.county}
        />
      )}
    </Modal>
  );
};

export const EditDocumentModal = (props: Props) => {
  return (
    <ProvideRootResource
      key={Number(props.isOpen)}
      initialValues={[[documentAtom, props.document]]}
    >
      <EditDocumentModalImplementation {...props} />
    </ProvideRootResource>
  );
};
