import { DndContext, DragMoveEvent, closestCenter } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  ColumnPinningState,
  Row,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  DataTableSkeleton,
  InlineLoading,
  InlineNotification,
} from 'carbon-components-react';
import { Date } from 'components/date';
import { DebouncedInput } from 'components/debounced-input';
import { Note } from 'components/note';
import { MIN_WIDTH } from 'components/table/column-actions';
import { ColumnFilter } from 'components/table/column-filter';
import { HiddenColumns } from 'components/table/hidden-columns';
import { TableActions } from 'components/table/table-actions';
import { useAtomValue } from 'jotai';
import { useColumnFilter } from 'lib/hooks/useColumnFilter';
import { useColumnSizing } from 'lib/hooks/useColumnSizing';
import { useColumnState } from 'lib/hooks/useColumnState';
import { useColumnVisibility } from 'lib/hooks/useColumnVisibilityAtom';
import usePinnedColumnReordering from 'lib/hooks/usePinnedColumnReordering';
import {
  DECIMAL_FORMATTER,
  DECIMAL_FORMATTER_TWO_FRACTIONS,
  FORMATTER,
} from 'lib/ui';
import plur from 'plur';
import { useCallback, useMemo, useRef, useState, Fragment } from 'react';
import { PackageEvaluationInventoryAssetsResponse } from 'types/packages-api/responses';
import { capitalizeFirstLetter } from 'utils/strings';
import { LoadPackage } from '../page';
import style from './assets.module.scss';
import { useCreateEvaluationInventoryAssetsAtom } from './atoms';
import { DragCell, DragHeader } from './draggable-table-components';
import {
  getInitialColumnOrder,
  getPinningStyles,
  handleDragEnd,
  handleDragMove,
} from './utils';

const columnHelper =
  createColumnHelper<PackageEvaluationInventoryAssetsResponse>();

const priceFormat = (v: unknown) => {
  if (!(typeof v === 'number')) return;
  return FORMATTER.format(v);
};

const EndDepth = ({
  startDepth,
  endDepth,
}: {
  startDepth: number | null;
  endDepth: number | null;
}) => {
  if (startDepth !== null && endDepth === null) return <>&infin;</>;
  if (endDepth === null) return <>--</>;
  return <>{DECIMAL_FORMATTER.format(endDepth)}</>;
};

const columns = [
  columnHelper.accessor((row) => row.assetName, {
    id: 'assetName',
    header: 'Asset',
    size: 140,
    footer: () => {
      return 'Totals';
    },
  }),
  columnHelper.accessor((row) => row.fullLegalDescription, {
    id: 'fullLegalDescription',
    header: 'Full Legal Description',
    size: 320,
  }),
  columnHelper.accessor((row) => row.assetType, {
    id: 'assetType',
    header: 'Asset Type',
    size: 150,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? capitalizeFirstLetter(v) : '--';
    },
  }),
  columnHelper.accessor((row) => row.county, {
    id: 'county',
    header: 'County',
    size: 120,
    cell: ({ row }) => {
      const county = row.original.county;
      const state = row.original.state;
      return (
        <>
          {county}, {state}
        </>
      );
    },
  }),
  columnHelper.accessor((row) => row.startDepth, {
    id: 'startDepth',
    header: 'Start Depth (ft)',
    enableGlobalFilter: false,
    cell: (row) => {
      const v = row.getValue();
      return v !== null && typeof v === 'number'
        ? DECIMAL_FORMATTER.format(v)
        : '--';
    },
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.endDepth, {
    id: 'endDepth',
    header: 'End Depth (ft)',
    enableGlobalFilter: false,
    cell: ({ row }) => {
      const startDepth = row.original.startDepth;
      const endDepth = row.original.endDepth;
      return (
        <EndDepth startDepth={Number(startDepth)} endDepth={Number(endDepth)} />
      );
    },
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.legacyDepth, {
    id: 'legacyDepth',
    header: 'Legacy Depth',
    size: 270,
    cell: (row) => {
      const value = row.getValue();
      return (
        <div className={style.notesColumn}>
          <Note noteText={value} />
        </div>
      );
    },
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.quarterCall, {
    id: 'quarterCall',
    header: 'Quarter Call',
  }),
  columnHelper.accessor((row) => row.tractInstrumentType, {
    id: 'tractInstrumentType',
    header: 'Tract Instrument Type',
    size: 220,
  }),
  columnHelper.accessor((row) => row.tractGrossAcres, {
    id: 'tractGrossAcres',
    header: 'Tract Gross Acres',
    size: 160,
    cell: (row) => {
      const v = row.getValue();
      return v ? DECIMAL_FORMATTER_TWO_FRACTIONS.format(v) : '--';
    },
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor((row) => row.tractNetAcres, {
    id: 'tractNetAcres',
    header: 'Tract Net Acres',
    size: 160,
    cell: ({ row }) => {
      const value = row.original.tractNetAcres;
      const units = row.original.tractNetAcreageUnits;
      return value !== null && value !== undefined
        ? value.toFixed(2) + ' ' + units
        : '--';
    },
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor((row) => row.inventoryAssetName, {
    id: 'inventoryAssetName',
    header: 'Inventory Asset Name',
    size: 200,
  }),
  columnHelper.accessor((row) => row.inventoryOwnerName, {
    id: 'inventoryOwnerName',
    header: 'Inventory Owner Name',
    size: 200,
  }),
  columnHelper.accessor((row) => row.inventoryOwnerType, {
    id: 'inventoryOwnerType',
    header: 'Inventory Owner Type',
    size: 200,
  }),
  columnHelper.accessor((row) => row.startEffectiveDate, {
    id: 'startEffectiveDate',
    header: 'Start Effective Date',
    size: 170,
    cell: (row) => {
      const v = row.getValue();
      return v ? <Date date={v} /> : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.endEffectiveDate, {
    id: 'endEffectiveDate',
    header: 'End Effective Date',
    size: 170,
    cell: (row) => {
      const v = row.getValue();
      return v ? <Date date={v} /> : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.daysHeld, {
    id: 'daysHeld',
    header: 'Days Held',
    size: 140,
    cell: (row) => {
      const v = row.getValue();
      return v ? DECIMAL_FORMATTER.format(v) : '--';
    },
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor((row) => row.inventoryOwnerType, {
    id: 'inventoryOwnerPortcoAbbreviation',
    header: 'Owner Portco Abbreviation',
    size: 230,
  }),
  columnHelper.accessor((row) => row.inventoryOwnerParentName, {
    id: 'inventoryOwnerParentName',
    header: 'Owner Parent Name',
    size: 170,
  }),
  columnHelper.accessor((row) => row.inventoryOwnerParentType, {
    id: 'inventoryOwnerParentType',
    header: 'Owner Parent Type',
    size: 170,
  }),
  columnHelper.accessor((row) => row.inventoryOwnerType, {
    id: 'inventoryOwnerParentPortcoAbbreviation',
    header: 'Owner Parent Portco Abbreviation',
    size: 270,
    cell: (row) => {
      const v = row.getValue();
      return v ? v : '--';
    },
  }),
  columnHelper.accessor((row) => row.acquisitionDate, {
    id: 'acquisitionDate',
    header: 'Acquisition Date',
    size: 170,
    cell: (row) => {
      const v = row.getValue();
      return v ? <Date date={v} /> : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.acquisitionUnitPrice, {
    id: 'acquisitionUnitPrice',
    header: 'Acquisition Unit Price',
    size: 180,
    cell: (row) => {
      const v = row.getValue();
      return FORMATTER.format(Number(v));
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.acquisitionTotalPrice, {
    id: 'acquisitionTotalPrice',
    header: 'Acquisition Total Price',
    size: 190,
    cell: (row) => {
      const v = row.getValue();
      return FORMATTER.format(Number(v));
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.saleDate, {
    id: 'saleDate',
    header: 'Sale Date',
    cell: (row) => {
      const v = row.getValue();
      return v ? <Date date={v} /> : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.saleUnitPrice, {
    id: 'saleUnitPrice',
    header: 'Sale Unit Price',
    size: 170,
    cell: (row) => {
      const v = row.getValue();
      return FORMATTER.format(Number(v));
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.saleTotalPrice, {
    id: 'saleTotalPrice',
    header: 'Sale Total Price',
    size: 170,
    cell: (row) => {
      const v = row.getValue();
      return FORMATTER.format(Number(v));
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.acquiredFromName, {
    id: 'acquiredFromName',
    header: 'Acquired From Name',
    size: 220,
    cell: (row) => {
      const v = row.getValue();
      return v ? v : '--';
    },
  }),
  columnHelper.accessor((row) => row.acquiredFromType, {
    id: 'acquiredFromType',
    header: 'Acquired From Type',
    size: 220,
    cell: (row) => {
      const v = row.getValue();
      return v ? v : '--';
    },
  }),
  columnHelper.accessor((row) => row.soldToName, {
    id: 'soldToName',
    header: 'Sold To Name',
    size: 220,
    cell: (row) => {
      const v = row.getValue();
      return v ? v : '--';
    },
  }),
  columnHelper.accessor((row) => row.soldToType, {
    id: 'soldToType',
    header: 'Sold To Type',
    size: 180,
    cell: (row) => {
      const v = row.getValue();
      return v ? v : '--';
    },
  }),
  columnHelper.accessor((row) => row.notes, {
    id: 'notes',
    header: 'Notes',
    size: 270,
    cell: (row) => {
      const value = row.getValue();
      return (
        <div className={style.notesColumn}>
          <Note noteText={value} />
        </div>
      );
    },
  }),
  columnHelper.accessor('targetDollarsPerAcre', {
    id: 'targetDollarsPerAcre',
    header: 'Target $/Acre',
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    cell: (row) => {
      const v = row.getValue();
      return v ? FORMATTER.format(v) : '--';
    },
    footer: ({ table }) => {
      const filteredRows = table.getFilteredRowModel().rows;

      // Initialize accumulators for acreage and target price
      let accumulatedTargetPrice = 0;
      let accumulatedAcreage = 0;

      // Accumulate total targetPrice and acreage from filtered rows
      filteredRows.forEach((row) => {
        accumulatedTargetPrice += row.original.targetPriceInDollars || 0;
        accumulatedAcreage += row.original.tractNetAcres || 0;
      });

      // Calculate targetPricePerAcre
      const targetPricePerAcre =
        accumulatedAcreage === 0
          ? 0
          : accumulatedTargetPrice / accumulatedAcreage;

      // Format and return the calculated value
      return FORMATTER.format(targetPricePerAcre);
    },
  }),
  columnHelper.accessor('targetPriceInDollars', {
    id: 'targetPriceInDollars',
    header: 'Target Price',
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    cell: (row) => {
      const value = row.getValue();
      return FORMATTER.format(Number(value));
    },
    footer: ({ table }) => {
      const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
        const price = row.original.targetPriceInDollars ?? 0;
        return sum + price;
      }, 0);

      return priceFormat(total);
    },
  }),
  columnHelper.accessor('maxDollarsPerAcre', {
    id: 'maxDollarsPerAcre',
    header: 'Max $/Acre',
    enableGlobalFilter: false,
    cell: (row) => {
      const v = row.getValue();
      return v ? FORMATTER.format(v) : '--';
    },
    meta: {
      filterVariant: 'range',
    },
    footer: ({ table }) => {
      const filteredRows = table.getFilteredRowModel().rows;

      // Initialize accumulators for acreage and target price
      let accumulateMaxPrice = 0;
      let accumulatedAcreage = 0;

      // Accumulate total maxPrice and acreage from filtered rows
      filteredRows.forEach((row) => {
        accumulateMaxPrice += row.original.maxPriceInDollars || 0;
        accumulatedAcreage += row.original.tractNetAcres || 0;
      });

      // Calculate targetDollarsPerAcre
      const targetDollarsPerAcre =
        accumulatedAcreage === 0 ? 0 : accumulateMaxPrice / accumulatedAcreage;

      // Format and return the calculated value
      return FORMATTER.format(targetDollarsPerAcre);
    },
  }),
  columnHelper.accessor('maxPriceInDollars', {
    id: 'maxPriceInDollars',
    header: 'Max Price',
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    cell: (row) => {
      const value = row.getValue();
      return FORMATTER.format(Number(value));
    },
    footer: ({ table }) => {
      const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
        const price = row.original.maxPriceInDollars ?? 0;
        return sum + price;
      }, 0);

      return priceFormat(total);
    },
  }),
];

const InventoryAssets = ({ href }: { href: string }) => {
  const assetsAtom = useCreateEvaluationInventoryAssetsAtom(href);
  const assets = useAtomValue(assetsAtom);

  if (assets.data && 'error' in assets.data)
    return (
      <InlineNotification kind="error" title={assets.data.error} lowContrast />
    );

  const allocationsData = assets.data?.map((el, i) => {
    return {
      id: String(i),
      ...el,
    };
  });

  if (!allocationsData) return <InlineLoading />;

  return <InventoryAssetsTable data={allocationsData} />;
};

const InventoryAssetsTable = ({
  data,
}: {
  data: PackageEvaluationInventoryAssetsResponse[];
}) => {
  const tableId = 'package-inventory-assets-table';
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnSizing, setColumnSizing] = useColumnSizing(tableId);
  const [columnVisibility, setColumnVisibility] = useColumnVisibility(tableId);
  const {
    columnFilters,
    isColumnFilterActive,
    setColumnFilters,
    setIsColumnFilterActive,
  } = useColumnFilter(tableId);

  const initialColumnPinning: ColumnPinningState = {
    left: ['assetName', 'fullLegalDescription'],
  };

  const initialColumnOrder = useMemo(
    () => getInitialColumnOrder(columns),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { columnOrder, setColumnOrder, columnPinning, setColumnPinning } =
    useColumnState({
      tableId: tableId,
      initialColumnOrder,
      initialColumnPinning,
    });

  const hoveredColumnIdRef = useRef<string | null>(null);

  usePinnedColumnReordering(columnPinning, columnOrder, setColumnOrder);

  const onDragMove = useCallback(
    (e: DragMoveEvent) => {
      handleDragMove(e, hoveredColumnIdRef);
    },
    [hoveredColumnIdRef]
  );

  const table = useReactTable({
    data: data || [],
    columnResizeMode: 'onChange',
    columns,
    defaultColumn: {
      minSize: MIN_WIDTH,
    },
    state: {
      sorting,
      globalFilter,
      columnSizing,
      columnVisibility,
      columnFilters,
      columnOrder,
      columnPinning,
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    initialState: {
      columnPinning: {
        left: ['assetName', 'fullLegalDescription'],
      },
      columnOrder: initialColumnOrder,
    },
    meta: {
      reset: {
        resetCustomizations: () => {
          setIsColumnFilterActive(false);
        },
      },
    },
  });

  const { rows } = table.getRowModel();

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48,
    overscan: 20,
  });

  if (!data) {
    return (
      <DataTableSkeleton
        showHeader={false}
        columnCount={columns.length}
        rowCount={10}
        showToolbar={false}
      />
    );
  }

  const isColumunVisible = table.getVisibleLeafColumns().length > 0;

  return (
    <LoadPackage
      render={() => (
        <>
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={(e) =>
              handleDragEnd(
                e,
                columnPinning,
                columnOrder,
                setColumnOrder,
                setColumnPinning,
                hoveredColumnIdRef
              )
            }
            modifiers={[restrictToHorizontalAxis]}
            onDragMove={onDragMove}
          >
            <div ref={parentRef} className={style.stackTableArea}>
              <div className={style.tableContext}>
                <div className={style.stackSearchArea}>
                  <DebouncedInput
                    className={style.stackSearchField}
                    value={globalFilter ?? ''}
                    onChange={(value) => setGlobalFilter(String(value).trim())}
                    placeholder="Search columns..."
                  />
                </div>
                <TableActions
                  table={table}
                  isColumnFilterActive={isColumnFilterActive}
                  setIsColumnFilterActive={(isColumnFilterActive) =>
                    setIsColumnFilterActive(isColumnFilterActive)
                  }
                ></TableActions>
              </div>

              {isColumunVisible ? (
                <table className={style.stackTable}>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Fragment key={headerGroup.id}>
                        <tr>
                          <SortableContext
                            items={columnOrder}
                            strategy={horizontalListSortingStrategy}
                          >
                            {headerGroup.headers.map((header) => (
                              <DragHeader
                                key={header.id}
                                header={header}
                                hoveredColumnIdRef={hoveredColumnIdRef}
                              />
                            ))}
                          </SortableContext>
                        </tr>
                        {isColumnFilterActive ? (
                          <tr>
                            <ColumnFilter
                              pinStyleGenerator={getPinningStyles}
                              headers={headerGroup.headers}
                            ></ColumnFilter>
                          </tr>
                        ) : null}
                      </Fragment>
                    ))}
                  </thead>
                  <tbody
                    style={{ minHeight: `${virtualizer.getTotalSize()}px` }}
                  >
                    {virtualizer.getVirtualItems().map((virtualRow, index) => {
                      const row = rows[
                        virtualRow.index
                      ] as Row<PackageEvaluationInventoryAssetsResponse>;
                      return (
                        <tr
                          key={row.id}
                          style={{
                            minHeight: `${virtualRow.size}px`,
                            transform: `translateY(${
                              virtualRow.start - index * virtualRow.size
                            }px)`,
                          }}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <SortableContext
                              key={cell.id}
                              items={columnOrder}
                              strategy={horizontalListSortingStrategy}
                            >
                              <DragCell
                                key={cell.id}
                                cell={cell}
                                row={row}
                                hoveredColumnIdRef={hoveredColumnIdRef}
                              />
                            </SortableContext>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    {table.getFooterGroups().map((footerGroup) => (
                      <tr key={footerGroup.id}>
                        {footerGroup.headers.map((header) => (
                          <th
                            style={{
                              ...getPinningStyles(header.column),
                            }}
                            key={header.id}
                            colSpan={header.colSpan}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.footer,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </tfoot>
                </table>
              ) : (
                <HiddenColumns className={style.hiddenColumn}></HiddenColumns>
              )}
            </div>
            {isColumunVisible ? (
              <div className={style.totalRowCount}>
                <label className={style.totalRows}>
                  {DECIMAL_FORMATTER.format(
                    table.getFilteredRowModel().rows.length
                  )}
                </label>
                {plur('row', table.getFilteredRowModel().rows.length)}
              </div>
            ) : null}
          </DndContext>
        </>
      )}
    />
  );
};

export { InventoryAssets };
