import { WellsResponse } from 'types/api-responses';
import { Date } from 'components/date';
import style from './well-details.module.scss';
import { Field } from './field';
import wellStatusTypes from 'enums/well_status_types.json';

export const WellDetailsContent = ({ well }: { well: WellsResponse }) => {
  const permitDate = <Date date={well.permitDate} fallback="–" />;
  const spudDate = <Date date={well.spudDate} fallback="–" />;
  const rigReleaseDate = <Date date={well.rigReleaseDate} fallback="–" />;
  const completionDate = <Date date={well.completionDate} fallback="–" />;
  const firstProductionDate = (
    <Date date={well.firstProductionDate} fallback="–" />
  );
  const lastProductionDate = (
    <Date date={well.lastProductionDate} fallback="–" />
  );

  return (
    <div className={style.detailsFields}>
      <Field label="SRP Well Name" value={well.name} />
      <Field label="Current Operator" value={well.currentOperator} />
      <Field label="Hole Direction" value={well.holeDirection} />
      <Field
        label="SRP Well Status"
        value={wellStatusTypes[well.status as keyof typeof wellStatusTypes]}
      />
      <Field label="Permit Date" value={permitDate} />
      <Field label="Spud Date" value={spudDate} />
      <Field label="Rig Release Date" value={rigReleaseDate} />
      <Field label="Completion Date" value={completionDate} />
      <Field label="First Production Date" value={firstProductionDate} />
      <Field label="Last Production Date" value={lastProductionDate} />
    </div>
  );
};
