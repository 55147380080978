import {
  InlineLoading,
  InlineNotification,
  Tab,
  Tabs,
} from 'carbon-components-react';
import { useState } from 'react';
import { PackageEvaluationResponse } from 'types/packages-api/responses';

import style from './result-tabs.module.scss';
import { UnitsAllocations } from './units-allocations';
import { Summary } from './summary';
import { Assets } from './assets';
import { InventoryAssets } from './inventory-assets';
import { FreeCashFlow } from './free-cash-flow';
import {
  subscribeToChannel,
  useDownloadResource,
} from 'lib/hooks/useDownloadFile';
import { DownloadButton } from 'components/download-button';
import { PackageSensitivities } from './package-sensitivities';
import { Warning16 } from '@carbon/icons-react';
import { SlotsAllocations } from './slots-allocations';
import { PackageOverviewCombined } from './package-overview-combined';
import { PackageOverview } from './package-overview';
import { LoadSlotAllocations } from './load-slot-allocations';
import { MapIsolator } from './map-isolator';
import { LoadPackage } from '../page';
import { Wells } from './wells';

import { buildMapPointsFromSlots, buildMapPointsFromUnits } from './utils';
import { useEvaluationDownloadsAtom } from './atoms';
import { useAtom } from 'jotai';
import { Comments } from 'components/events-panel';

const DownloadEvaluationSimple = ({
  downloadHref,
  buttonText,
}: {
  downloadHref: string;
  buttonText: string;
}) => {
  const downloadOwnership = useDownloadResource(downloadHref);

  return (
    <DownloadButton
      loading={downloadOwnership.generatingDownload}
      loadingText="Building export"
      kind="primary"
      onClick={() => downloadOwnership.triggerDownload()}
    >
      {buttonText}
    </DownloadButton>
  );
};

const ExcelExport = ({
  id,
  href,
  buttonText,
}: {
  id: string;
  href: string;
  buttonText: string;
}) => {
  const downloadResource = useDownloadResource(href);
  const downloadsAtom = useEvaluationDownloadsAtom(href);
  const [request, requestDownloads] = useAtom(downloadsAtom);
  if (request.loading) return <InlineLoading />;
  if ('error' in request && request.error) {
    return (
      <InlineNotification kind="error" title={request.error} lowContrast />
    );
  }
  if (request.data && !('error' in request.data)) {
    const downloads = request.data;
    if (downloads.length) {
      const lastDownload = downloads[0];
      const isProcessing =
        lastDownload.status === 'processing' ||
        lastDownload.status === 'pending';

      if (isProcessing) {
        subscribeToChannel(
          lastDownload.notifications.channel,
          lastDownload.notifications.events[0]['name'],
          lastDownload.href,
          () => {
            requestDownloads();
          }
        );

        return <InlineLoading description="Building Export" />;
      }
    }

    return (
      <DownloadButton
        id={id}
        loading={downloadResource.generatingDownload}
        loadingText="Building export"
        kind="primary"
        onClick={() => {
          if (!downloads.length) {
            downloadResource.triggerDownload((processing) => {
              if (!processing) {
                requestDownloads();
              }
            });
          } else {
            window.open(downloads[0].fileUrl, '_blank', 'noopener,noreferrer');
          }
        }}
      >
        {buttonText}
      </DownloadButton>
    );
  }

  return null;
};

const ResultTabs = ({
  evaluation,
  isCombined = false,
}: {
  evaluation: PackageEvaluationResponse;
  isCombined?: boolean;
}) => {
  return (
    <div className={style.resultTab}>
      <div className={style.titleArea}>
        <div className={style.summaryData}>
          <Summary data={evaluation} isCombined={isCombined} />
        </div>
        <div className={style.downloadButtons}>
          <LoadPackage
            render={(packageData) => {
              return (
                <LoadSlotAllocations
                  slotHref={evaluation.resources.slots.href}
                  unitHref={evaluation.resources.allocations.href}
                  renderUnits={(allocations) => {
                    const mapPoints = buildMapPointsFromUnits(
                      allocations || []
                    );
                    return (
                      <MapIsolator
                        packageData={packageData}
                        mapPoints={mapPoints}
                        type="units"
                      />
                    );
                  }}
                  renderSlots={(allocations) => {
                    const mapPoints = buildMapPointsFromSlots(
                      allocations || []
                    );
                    return (
                      <MapIsolator
                        packageData={packageData}
                        mapPoints={mapPoints}
                        type="slots"
                      />
                    );
                  }}
                />
              );
            }}
          />
          {evaluation.resources.evaluationDetailsDownload && (
            <ExcelExport
              id="package-exports"
              href={evaluation.resources.evaluationDetailsDownload.href}
              buttonText="Evaluation Details"
            />
          )}
          {evaluation.resources.inventoryAssetsDownload && (
            <DownloadEvaluationSimple
              downloadHref={evaluation.resources.inventoryAssetsDownload.href}
              buttonText="Inventory Assets"
            />
          )}
          {evaluation.resources.cashflowsBySubtractDownload && (
            <DownloadEvaluationSimple
              downloadHref={
                evaluation.resources.cashflowsBySubtractDownload.href
              }
              buttonText="Cashflows By Subtract"
            />
          )}
          {evaluation.resources.kpiDownload && (
            <DownloadEvaluationSimple
              downloadHref={evaluation.resources.kpiDownload.href}
              buttonText="Eric's Query"
            />
          )}
        </div>
      </div>
      <TabList evaluation={evaluation} isCombined={isCombined} />
    </div>
  );
};

function TabList({
  evaluation,
  isCombined = false,
}: {
  evaluation: PackageEvaluationResponse;
  isCombined: boolean;
}) {
  const [selected, setSelected] = useState(0);
  const [eventsCount, setEventsCount] = useState(evaluation.eventsCount);
  const commentsTabIndex = evaluation.resources.wells ? 7 : 6;
  return (
    <Tabs className={style.tabsContained} selected={selected}>
      <Tab onClick={() => setSelected(0)} label="Package Overview">
        {selected === 0 &&
          (isCombined ? (
            <PackageOverviewCombined
              href={evaluation.resources.packageOverview.href}
            />
          ) : (
            <PackageOverview href={evaluation.resources.packageOverview.href} />
          ))}
      </Tab>
      <Tab onClick={() => setSelected(1)} label="Assets">
        {selected === 1 &&
          (evaluation.resources.inventoryAssets?.href ? (
            <InventoryAssets href={evaluation.resources.inventoryAssets.href} />
          ) : (
            <Assets href={evaluation.resources.assets.href} />
          ))}
      </Tab>
      <Tab
        onClick={() => setSelected(2)}
        label={
          <>
            Unit Allocations
            {evaluation.resources.allocations.hasWarning && (
              <span className={style.warningIcon}>
                <Warning16 />
              </span>
            )}
          </>
        }
      >
        {selected === 2 && (
          <UnitsAllocations href={evaluation.resources.allocations.href} />
        )}
      </Tab>
      <Tab onClick={() => setSelected(3)} label={<>Slots Allocations</>}>
        {selected === 3 && (
          <SlotsAllocations href={evaluation.resources.slots.href} />
        )}
      </Tab>
      <Tab onClick={() => setSelected(4)} label="Free Cash Flow">
        {selected === 4 && (
          <FreeCashFlow href={evaluation.resources.valuationOverview.href} />
        )}
      </Tab>
      <Tab onClick={() => setSelected(5)} label="Sensitivities">
        {selected === 5 && (
          <PackageSensitivities
            href={evaluation.resources.sensitivitiesOverview.href}
          />
        )}
      </Tab>
      {evaluation.resources.wells && (
        <Tab onClick={() => setSelected(6)} label="Wells">
          {selected === 6 && <Wells href={evaluation.resources.wells.href} />}
        </Tab>
      )}
      <Tab
        onClick={() => setSelected(commentsTabIndex)}
        label={`Comments (${eventsCount})`}
      >
        {selected === commentsTabIndex && (
          <Comments
            eventsHref={evaluation.resources.events.href}
            commentsHref={evaluation.resources.comments.href}
            onComment={() => setEventsCount(eventsCount + 1)}
          />
        )}
      </Tab>
    </Tabs>
  );
}

export { ResultTabs };
