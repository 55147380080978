import { format, isValid, parse } from 'date-fns';
import moment from 'moment';

const getCurrentDateString = (): string => {
  const date = new Date();
  return format(date, 'yyyy-MM-dd');
};

const getDateString = (date: Date): string => {
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (e) {
    return '';
  }
};

const parseDateString = (dateString: string | null): Date | null => {
  if (!dateString) {
    return null;
  }

  try {
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    return isValid(parsedDate) ? parsedDate : null;
  } catch (e) {
    return null;
  }
};

// This function formats the date and time to UTC format, time is optional
function parseUTCdateTime(
  date: string | Date | undefined,
  includeTimeStamp: boolean = false
): string {
  if (date) {
    return moment
      .utc(date)
      .format(includeTimeStamp ? 'M/DD/YYYY hh:mm A' : 'MM/DD/YYYY');
  }
  return '';
}

export {
  getCurrentDateString,
  getDateString,
  parseDateString,
  parseUTCdateTime,
};
