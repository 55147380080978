import { useRef } from 'react';
import { WellsResponse } from 'types/api-responses';

// Fetch well analysis hrefs from well data. Preserves the hrefs in a ref to use it across renders.
export const useWellAnalysisHrefs = (well?: WellsResponse) => {
  const wellAnalysisHrefs = useRef<{
    productionRecordsHref: string;
    productionRecordsVersionsHref: string;
    productionForecastsHref: string;
    productionForecastsVersionsHref: string;
  }>();

  if (well && !wellAnalysisHrefs.current) {
    wellAnalysisHrefs.current = {
      productionRecordsHref: well.resources.queries.productionRecords.href,
      productionRecordsVersionsHref:
        well.resources.queries.productionRecordsVersions.href,
      productionForecastsHref: well.resources.queries.productionForecasts.href,
      productionForecastsVersionsHref:
        well.resources.queries.productionForecastsVersions.href,
    };
  }
  return wellAnalysisHrefs.current;
};
