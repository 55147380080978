import {
  InlineNotification,
  OverflowMenu,
  SkeletonText,
} from 'carbon-components-react';
import { EventsPanel, EventsPanelOpenButton } from 'components/events-panel';
import { Well as WellIcon } from 'components/icons/well';
import { PageHeader } from 'components/page-header';
import { WellPageContent } from 'components/well-page-content';
import { useAtomValue } from 'jotai';
import { useLocation } from 'react-router-dom';
import { WellsResponse, WellVersionsResponse } from 'types/api-responses';
import { useWellAtom } from './atoms';
import { UploadFileButton } from 'components/well-page-content/upload-file-button';
import { CaretDown16, Checkmark16 } from '@carbon/icons-react';

import style from './well-page.module.scss';
import { useState } from 'react';
import { useWellVersionsAtom } from 'components/well-page-content/atoms';
import classNames from 'classnames';
import { parseUTCdateTime } from 'utils/dates';

const WellVersionSwitcher = ({
  well,
  onVersionChange,
}: {
  well: WellsResponse;
  onVersionChange: (version: string) => void;
}) => {
  const wellVersionAtom = useWellVersionsAtom(
    `${well.resources.versions.href}`
  );
  // Getting well versions and displaying in dropdown
  const wellVersions = useAtomValue(wellVersionAtom);

  if (wellVersions.loading || wellVersions.data === undefined) {
    return (
      <SkeletonText className={style.versionSwitcherSkeleton} width={'30px'} />
    );
  }

  if ('error' in wellVersions.data) {
    return (
      <InlineNotification
        kind="error"
        title={'Error loading version details for this well.'}
        lowContrast
      />
    );
  }

  const selectedVersion = wellVersions.data.find(
    (version) => version.versionId === well.versionId
  );

  const versionLabelRenderer = (
    version: WellVersionsResponse[number],
    isTextHighlight = false
  ) => {
    // Formatting the date to display in the dropdown list
    const formattedDisplayTime =
      version.versionId === 'current'
        ? parseUTCdateTime(version.beginTime, true)
        : `${parseUTCdateTime(version.beginTime, true)}${
            version.endTime
              ? ` - ${parseUTCdateTime(version.endTime, true)}`
              : ''
          }`;

    return (
      <span
        className={classNames({
          [style.selectedVersionLabel]: isTextHighlight,
        })}
      >
        {version.versionName}
        {version.versionId === 'current' && ' Current'}&nbsp;
        <span>({formattedDisplayTime})</span>
      </span>
    );
  };

  return (
    <div className={style.versionSwitcherContainer}>
      {selectedVersion ? (
        <span>{versionLabelRenderer(selectedVersion, true)}</span>
      ) : null}

      <OverflowMenu
        renderIcon={CaretDown16}
        className={style.versionSwitcherMenu}
        size="sm"
        data-testid="wells-version-switcher"
      >
        <>
          <ul className={style.versionSwitcherList}>
            {wellVersions.data.map((version) => {
              // This calculation is for initial check mark display
              const isSelectedVersion = well.versionId === version.versionId;
              return (
                <li
                  key={version.versionName}
                  onClick={() => {
                    onVersionChange(version.versionId);
                  }}
                >
                  <Checkmark16
                    visibility={isSelectedVersion ? 'visible' : 'hidden'}
                  />
                  {versionLabelRenderer(version, isSelectedVersion)}
                </li>
              );
            })}
          </ul>
        </>
      </OverflowMenu>
    </div>
  );
};

const Header = ({
  well,
  onVersionChange,
}: {
  well: WellsResponse | undefined;
  onVersionChange: (version: string) => void;
}) => {
  const { pathname } = useLocation();
  return (
    <PageHeader
      breadcrumbs={[
        ['/wells', 'Wells'],
        [pathname, well?.uwi || <SkeletonText width="200px" />],
      ]}
      title={
        well?.uwi ? `UWI ${well.uwi}` : <SkeletonText heading width="200px" />
      }
      titleDetail={
        well ? (
          <WellVersionSwitcher well={well} onVersionChange={onVersionChange} />
        ) : null
      }
      iconComponent={<WellIcon medium hasBackground={false} />}
      actions={
        <>
          {well ? <UploadFileButton /> : null}
          <EventsPanelOpenButton />
        </>
      }
    ></PageHeader>
  );
};

const EventsContent = ({ well }: { well: WellsResponse }) => {
  return (
    <EventsPanel
      header={well.name}
      title="Well"
      helperText="Context allows you to share information about a well."
      eventsHref={well.resources.events.href}
      commentsHref={well.resources.comments.href}
    />
  );
};

const ShowWellPage = () => {
  const { pathname } = useLocation();
  // Maintaining the wells version href and loading the page with the selected href
  const [versionId, setVersionId] = useState<string | undefined>(undefined);
  const wellAtom = useWellAtom(pathname, versionId);
  const well = useAtomValue(wellAtom);

  if (well.data && 'error' in well.data)
    return (
      <InlineNotification kind="error" title={well.data.error} lowContrast />
    );

  return (
    <>
      <Header well={well.data} onVersionChange={setVersionId} />
      <WellPageContent well={well.data} />
      {well.data ? <EventsContent well={well.data} /> : null}
    </>
  );
};

export { ShowWellPage };
