// document-attributes-detail-view.tsx

import React from 'react';
import { useAtomValue } from 'jotai';
import { DocumentResponse } from 'types/api-responses';
import { Date } from 'components/date';
import { useStatesAtom } from './atoms';
import { resourcesAtom } from 'atoms/root';

import style from './document-attributes-detail-view.module.scss';

const NULL_DISPLAY_VALUE = '--';

const CountyName = ({ county }: { county: DocumentResponse['county'] }) => {
  const resources = useAtomValue(resourcesAtom);
  const statesAtom = useStatesAtom(resources?.states.href);
  const states = useAtomValue(statesAtom);
  if (county) {
    if (states.data && 'error' in states.data) return null;
    const state = states.data?.find((state) => state.id === county.stateId);
    return <>{`${county.name}, ${state?.name || county.state.abbreviation}`}</>;
  } else {
    return <>{`${NULL_DISPLAY_VALUE}`}</>;
  }
};

const DocumentAttributesDetailView: React.FC<{
  document: DocumentResponse;
}> = ({ document }) => {
  return (
    <div className={style.documentAttributes}>
      <h3 className={style.title}>Details</h3>
      <div className={style.attributesGrid}>
        <div className={style.attributeItem}>
          <span className={style.label}>County</span>
          <span className={style.value}>
            <CountyName county={document.county} />
          </span>
        </div>
        <div className={style.attributeItem}>
          <span className={style.label}>Book Type</span>
          <span className={style.value}>
            {document.bookType || NULL_DISPLAY_VALUE}
          </span>
        </div>
        <div className={style.attributeItem}>
          <span className={style.label}>Document Type</span>
          <span className={style.value}>
            {document.documentType || NULL_DISPLAY_VALUE}
          </span>
        </div>
        <div className={style.attributeItem}>
          <span className={style.label}>Instrument Number</span>
          <span className={style.value}>
            {document.instrumentNumber || 'Unknown'}
          </span>
        </div>
        <div className={style.attributeItem}>
          <span className={style.label}>Effective Date</span>
          <span className={style.value}>
            <Date date={document.effectiveDate} fallback="(none)" />
          </span>
        </div>
        <div className={style.attributeItem}>
          <span className={style.label}>Instrument Date</span>
          <span className={style.value}>
            <Date date={document.instrumentDate} fallback="(none)" />
          </span>
        </div>
        <div className={style.attributeItem}>
          <span className={style.label}>File Date</span>
          <span className={style.value}>
            <Date date={document.fileDate} fallback="(none)" />
          </span>
        </div>
        <div className={style.attributeItem}>
          <span className={style.label}>Recorded Date</span>
          <span className={style.value}>
            <Date date={document.recordedDate} fallback="(none)" />
          </span>
        </div>
      </div>
    </div>
  );
};

export { DocumentAttributesDetailView };
