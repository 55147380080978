import React, { useState } from 'react';
import {
  Modal,
  Button,
  Dropdown,
  TextInput,
  TooltipDefinition,
} from 'carbon-components-react';
import { Add16, Information16 } from '@carbon/icons-react';
import styles from './add-document-modal.module.scss';
import { useConfigurationOptionsAtom, getFormDefaults } from './atoms';
import { CompletionConfigurationResponse } from 'types/api-responses';
import { createMutationAtom } from 'atoms/create-resource-atom';
import { useAtomValue, useAtom } from 'jotai';
import { ApiPayloads } from 'types/api-payloads';
import { useHistory } from 'react-router-dom';
import routes from '~/routes';

const submitDocumentAtom = createMutationAtom<any, 'courthouse_documents_v2'>();

const AddDocumentModalContainer = () => {
  const configurationOptionsAtom =
    useConfigurationOptionsAtom('/configurations');
  const configuration = useAtomValue(configurationOptionsAtom);
  const configurationObject = configuration.data;
  if (!configurationObject || 'error' in configurationObject) return null;

  return <AddDocumentModal configurationObject={configurationObject} />;
};

const AddDocumentModal = ({
  configurationObject,
}: {
  configurationObject: CompletionConfigurationResponse;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const formDefaults = getFormDefaults(configurationObject);
  const [ocrModel, setOcrModel] = useState(
    formDefaults.extractionConfigurations
  );
  const [llmModel, setLlmModel] = useState(
    formDefaults.completionConfigurations
  );
  const [promptVersion, setPromptVersion] = useState(
    formDefaults.promptConfigurations
  );
  const [, submitDocument] = useAtom(submitDocumentAtom);
  const history = useHistory();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile && uploadedFile.size <= 25 * 1024 * 1024) {
      setFile(uploadedFile);
    } else {
      console.error('File size exceeds 25 MB limit');
      // You might want to show an error message to the user here
    }
  };

  const handleSubmit = async () => {
    if (!file || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      // TODO: move to DirectUpload for better performance
      const reader = new FileReader();

      const readerPromise = new Promise((resolve, reject) => {
        reader.onload = () => {
          if (!reader.result) {
            reject(new Error('File failed to upload'));
            return;
          }
          resolve(reader.result);
        };
        reader.onerror = () => reject(reader.error);
      });

      reader.readAsDataURL(file);
      const result = (await readerPromise) as ArrayBuffer;

      await sendRequest({
        documentExtraction: {
          configurationId: ocrModel.id,
        },
        documentExtractionCompletion: {
          configurationId: llmModel.id,
          promptId: promptVersion.id,
        },
        file: result,
      });
    } catch (error) {
      console.error('Error processing file:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const sendRequest = async (
    jsonData: ApiPayloads['courthouse_documents_v2']['payload']
  ) => {
    await submitDocument({
      type: 'POST',
      url: '/courthouse_documents_v2',
      data: jsonData,
      onSuccess: (data) => {
        console.log('Document submitted successfully:', data);
        const path = routes.courthouseDocumentPath.replace(
          ':id',
          data.documentId.toString()
        );
        history.push(path);
        setIsOpen(false);
      },
      onError: (error) => {
        console.error('Error submitting document:', error);
      },
    });
  };

  const closeModal = () => {
    if (!isSubmitting) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <Button size="sm" renderIcon={Add16} onClick={() => setIsOpen(true)}>
        <span>Add Document</span>
      </Button>
      <Modal
        open={isOpen}
        onRequestClose={closeModal}
        modalHeading="Add Document"
        primaryButtonText="Add Document"
        secondaryButtonText="Cancel"
        primaryButtonDisabled={!file || isSubmitting}
        onRequestSubmit={handleSubmit}
        className={styles.addDocumentModal}
        shouldSubmitOnEnter={false}
      >
        <div className={styles.sectionWrapper}>
          <h4 className={styles.sectionTitle}>Upload files</h4>
          <p className={styles.helperText}>
            Upload a single PDF file (max 25 mb limit)
          </p>
          <div className={styles.uploadWrapper}>
            <Button
              kind="tertiary"
              onClick={() => document.getElementById('file-upload')?.click()}
              disabled={isSubmitting}
            >
              Upload
            </Button>
            <input
              type="file"
              id="file-upload"
              accept=".pdf"
              style={{ display: 'none' }}
              onChange={handleFileUpload}
              disabled={isSubmitting}
            />
            {file && (
              <TextInput
                id="filename"
                labelText="File name"
                value={file.name}
                readOnly
                className={styles.filenameInput}
              />
            )}
          </div>
        </div>

        <div className={styles.sectionWrapper}>
          <h4 className={styles.sectionTitle}>
            Configure Provision
            <TooltipDefinition
              tooltipText="Adding this document will trigger auto-provisioning. Configure the fields below to customize the output."
              direction="bottom"
              align="start"
              className={styles.tooltip}
            >
              <Information16 className={styles.infoIcon} />
            </TooltipDefinition>
          </h4>
          <Dropdown
            id="ocr-model"
            titleText="OCR Model"
            label="Select OCR Model"
            itemToString={(item) =>
              `${item.provider} ${item.version}${
                item.default ? ' (default)' : ''
              }`
            }
            items={configurationObject.extractionConfigurations}
            selectedItem={ocrModel}
            onChange={({ selectedItem }) =>
              selectedItem && setOcrModel(selectedItem)
            }
            className={styles.dropdown}
            disabled={isSubmitting}
          />
          <Dropdown
            id="llm-model"
            titleText="LLM Model"
            label="Select LLM Model"
            itemToString={(item) =>
              `${item.provider} ${item.version}${
                item.default ? ' (default)' : ''
              }`
            }
            items={configurationObject.completionConfigurations}
            selectedItem={llmModel}
            onChange={({ selectedItem }) =>
              selectedItem && setLlmModel(selectedItem)
            }
            className={styles.dropdown}
            disabled={isSubmitting}
          />
          <Dropdown
            id="prompt-version"
            titleText="Prompt Version"
            label="Select Prompt Version"
            itemToString={(item) =>
              `${item.version}${item.default ? ' (default)' : ''}`
            }
            items={configurationObject.promptConfigurations}
            selectedItem={promptVersion}
            onChange={({ selectedItem }) =>
              selectedItem && setPromptVersion(selectedItem)
            }
            className={styles.dropdown}
            disabled={isSubmitting}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddDocumentModalContainer;
