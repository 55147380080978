import { DocumentsSearch } from 'components/documents-search';
import { PageHeader } from 'components/page-header';
import { SearchTabs } from 'components/search-tabs';
import AddDocumentModal from 'components/add-document-modal/add-document-modal';
import type { FeatureFlagWindow } from 'types/window';

declare let window: FeatureFlagWindow;

const Header = () => {
  const showAddDocument = window.CONFIG?.featureFlags.autoprovisionerV2;

  return (
    <PageHeader
      breadcrumbs={[]}
      title={'Search'}
      actions={<div>{showAddDocument && <AddDocumentModal />}</div>}
    >
      <SearchTabs />
    </PageHeader>
  );
};

const DocumentSearchPage = () => {
  return (
    <>
      <Header />
      <DocumentsSearch noGutter showTips />
    </>
  );
};

export { DocumentSearchPage };
