import { JSONRenderer } from 'components/json-renderer';
import {
  Button,
  InlineLoading,
  InlineNotification,
  Tab,
  Tabs,
  Link,
  Tile,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  Modal,
  TextInput,
  Grid,
  Row,
  Column,
  Dropdown,
  ComposedModal,
  ModalHeader,
  ModalBody,
} from 'carbon-components-react';
import { Provider, useAtom, useAtomValue, useStore, useSetAtom } from 'jotai';
import { Date } from 'components/date';
import { format, parseISO } from 'date-fns';
import {
  getFormDefaults,
  getPayload,
  provisionAtom,
  provisionHrefAtom,
  provisionListAtom,
  provisionsAtom,
  provisionsHrefAtom,
  useClearStore,
  useCompletionItems,
  useConfigurationOptionsAtom,
  useOCRExtractionAtom,
  refreshProvisionCounter,
  isCompositeProvision,
  currentProvisionIdAtom,
  refreshProvisionsCounter,
} from './atoms';
import {
  subscribeToChannel,
  useProvisionDocument,
} from 'lib/hooks/useProvisionDocument';
import {
  CompletionConfigurationResponse,
  DocumentResponse,
  OCRExtractionResponse,
  ProvisionResponse,
} from 'types/api-responses';
import { JotaiStore } from 'lib/types';
import { SuccessToast } from 'components/success-toast';
import { useState, useReducer, useRef } from 'react';
import ErrorToast from 'components/error-toast';
import { EmptyState } from 'components/empty-state';
import { Default } from 'components/icons';
import {
  CaretDown16,
  Checkmark16,
  CaretRight16,
  CaretLeft16,
  Information16,
  SysProvision16,
  CalendarSettings16,
  DocumentPdf32,
  Restart16,
} from '@carbon/icons-react';
import styles from '../json-renderer/json-renderer.module.scss';
import { useComponentVisible } from 'lib/hooks/useComponentVisible';
import { EventsPanel, EventsPanelOpenButton } from 'components/events-panel';
import { DebouncedInput } from 'components/debounced-input';
import classNames from 'classnames';
import { useDownloadResource } from 'lib/hooks/useDownloadFile';
import { useBodyClass } from 'lib/hooks/useBodyClass';
import { DownloadButton } from 'components/download-button';
import { CopyButton } from 'components/copy-button/copy-button';
import { Pagination } from 'components/pagination';
import { createMutationAtom } from 'atoms/create-resource-atom';
import type { FeatureFlagWindow } from 'types/window';

import modalStyles from './provisioned-document.module.scss';

declare let window: FeatureFlagWindow;

const reapplyDocumentAtom = createMutationAtom<
  any,
  'autoProvisionedDocumentProcessJob'
>();

export function List({
  store,
  onProvisionsAvailable,
  document,
}: {
  store: JotaiStore;
  onProvisionsAvailable: () => void;
  document?: DocumentResponse;
}) {
  return (
    <Provider store={store}>
      <FetchListChecker
        onProvisionsAvailable={onProvisionsAvailable}
        document={document}
      />
    </Provider>
  );
}

export function AvailableProvisions({
  href,
  configurationHref,
  onProvisionsAvailable,
  store,
  hasImage,
}: {
  href: string;
  hasImage: boolean;
  configurationHref: string;
  onProvisionsAvailable: () => void;
  store: JotaiStore;
}) {
  return (
    <Provider store={store}>
      <Provisions
        href={href}
        configurationHref={configurationHref}
        onProvisionsAvailable={onProvisionsAvailable}
        hasImage={hasImage}
      />
    </Provider>
  );
}

function FetchListChecker({
  onProvisionsAvailable,
  document,
}: {
  onProvisionsAvailable: () => void;
  document?: DocumentResponse;
}) {
  const provisions = useAtomValue(provisionsAtom);
  const newProvisionList = useAtomValue(provisionListAtom);

  if (provisions.state === 'loading')
    return <InlineLoading description="Loading provisions..." />;

  const list = newProvisionList.length
    ? newProvisionList
    : provisions.state === 'hasData' &&
      provisions.data &&
      !('error' in provisions.data)
    ? provisions.data
    : [];

  if (!list.length && provisions.state === 'hasData')
    return (
      <Tile className={styles.emptyStateCol}>
        <EmptyState
          IconComponent={Default}
          headerText="No Provisions Found"
          helperText="No Provisions Generated Yet."
        />
      </Tile>
    );

  return (
    <FetchSingleProvision
      key={list.length}
      provisions={list}
      onProvisionsAvailable={onProvisionsAvailable}
      document={document}
    />
  );
}

function ViewDocumentModal({
  isOpen,
  onClose,
  currentProvision,
  provisions,
  setProvision,
  onProvisionsAvailable,
  provisionHref,
  document,
}: {
  isOpen: boolean;
  onClose: () => void;
  currentProvision: ProvisionResponse;
  provisions: ProvisionResponse[];
  setProvision: React.Dispatch<React.SetStateAction<ProvisionResponse>>;
  onProvisionsAvailable: () => void;
  provisionHref: string;
  document?: DocumentResponse;
}) {
  const store = useStore();
  store.set(provisionHrefAtom, provisionHref);
  const provisionReq = useAtomValue(provisionAtom);

  if (provisionReq.state === 'loading') {
    return <InlineLoading description="Loading provisions..." />;
  }

  if (provisionReq.state === 'hasError') {
    return (
      <InlineNotification
        kind="error"
        lowContrast
        title="Error loading provisions"
      />
    );
  }

  if (
    provisionReq.state === 'hasData' &&
    provisionReq.data &&
    !('error' in provisionReq.data)
  ) {
    return (
      <ComposedModal
        open={isOpen}
        onClose={onClose}
        size="lg"
        className={modalStyles.viewDocumentModal}
      >
        <ModalHeader title="View Document" />
        <ModalBody className={modalStyles.modalBody}>
          <div className={modalStyles.modalContentSplit}>
            <div className={modalStyles.leftPane}>
              {/* Left side: Provisions */}
              <ProvisionSwitcher
                currentProvision={currentProvision}
                provisions={provisions}
                setProvision={setProvision}
                onProvisionsAvailable={onProvisionsAvailable}
              />
              <ProvisionTabs
                provision={provisionReq.data}
                onPaginateParagraphs={() => {}}
              />
            </div>
            <div className={modalStyles.rightPane}>
              {/* Right side: Document iframe */}
              {document?.resources?.image && (
                <iframe
                  src={document.resources.image?.href}
                  title="Document Viewer"
                  className={modalStyles.documentIframe}
                />
              )}
            </div>
          </div>
        </ModalBody>
      </ComposedModal>
    );
  }

  return null;
}

function ViewDocumentButton({ onClick }: { onClick: () => void }) {
  return (
    <Button
      hasIconOnly
      kind="tertiary"
      size="md"
      renderIcon={DocumentPdf32}
      iconDescription="View Document"
      tooltipAlignment="center"
      tooltipPosition="bottom"
      onClick={onClick}
    />
  );
}

function FetchSingleProvision({
  provisions,
  onProvisionsAvailable,
  document,
}: {
  provisions: ProvisionResponse[];
  onProvisionsAvailable: () => void;
  document?: DocumentResponse;
}) {
  const store = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProvision, setCurrentProvision] = useState(provisions[0]);
  const provisionHref = currentProvision.href;
  store.set(provisionHrefAtom, provisionHref);
  store.set(currentProvisionIdAtom, currentProvision.id);
  const provisionReq = useAtomValue(provisionAtom);
  const provisionsHref = useAtomValue(provisionsHrefAtom);
  const { triggerProvision, generatingProvision } =
    useProvisionDocument(provisionsHref);
  const shouldShowProvisionBanner =
    window.CONFIG?.featureFlags.autoprovisionerV2 &&
    !generatingProvision &&
    !provisions.some(isCompositeProvision) &&
    provisionReq.state === 'hasData' &&
    provisionReq.data &&
    !('error' in provisionReq.data) &&
    !(provisionReq.data?.status === 'queued');

  const outputRef = useRef<HTMLDivElement | null>(null);
  const onPaginateParagraphs = () => {
    if (outputRef.current) {
      outputRef.current.scrollTo(0, 0);
    }
  };

  if (provisionReq.state === 'loading')
    return <InlineLoading description="Loading provisions..." />;
  if (provisionReq.state === 'hasError')
    return (
      <InlineNotification
        kind="error"
        lowContrast
        title="Error loading provisions"
      />
    );

  if (
    provisionReq.state === 'hasData' &&
    provisionReq &&
    provisionReq.data &&
    !('error' in provisionReq.data)
  )
    return (
      <div className={styles.provisionsPanel} ref={outputRef}>
        <ViewDocumentModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          currentProvision={currentProvision}
          provisions={provisions}
          setProvision={setCurrentProvision}
          onProvisionsAvailable={onProvisionsAvailable}
          provisionHref={provisionHref}
          document={document}
        />
        <div className={styles.provisionsTitle}>
          <ProvisionSwitcher
            currentProvision={currentProvision}
            provisions={provisions}
            setProvision={setCurrentProvision}
            onProvisionsAvailable={onProvisionsAvailable}
          />
          <div>
            <ViewDocumentButton onClick={() => setIsModalOpen(true)} />
            <EventsPanelOpenButton
              tooltipPosition="bottom"
              tooltipAlignment="end"
            />
            <MetadataModal
              metadata={currentProvision.metadata}
              createdAt={currentProvision.createdAt}
            />
          </div>
        </div>
        {shouldShowProvisionBanner && (
          <div className={styles.provisionsBanner}>
            <InlineNotification
              kind="info"
              lowContrast
              hideCloseButton={true}
              title="Please re-run the provisioner to access new features"
              actions={
                <Button
                  size="sm"
                  kind="ghost"
                  disabled={generatingProvision}
                  onClick={() => {
                    triggerProvision({
                      onGeneratingProvision: () => {
                        onProvisionsAvailable();
                      },
                      onError: () => {
                        // Error will be handled by existing error notifications
                      },
                    });
                  }}
                >
                  {generatingProvision ? (
                    <InlineLoading description="Provisioning..." />
                  ) : (
                    'Provision'
                  )}
                </Button>
              }
            />
          </div>
        )}
        <div className={styles.provisionsContent}>
          <EventsContent provision={provisionReq.data} />
          <ProvisionTabs
            provision={provisionReq.data}
            onPaginateParagraphs={onPaginateParagraphs}
          />
        </div>
      </div>
    );

  return null;
}

function Provisions({
  href,
  configurationHref,
  onProvisionsAvailable,
  hasImage,
}: {
  href: string;
  configurationHref: string;
  onProvisionsAvailable: () => void;
  hasImage: boolean;
}) {
  useClearStore();

  const store = useStore();
  store.set(provisionsHrefAtom, href);

  const provisions = useAtomValue(provisionsAtom);
  const [currentProvisions] = useAtom(provisionListAtom);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showTooltip, setShowTooltip] = useReducer((s) => !s, false);
  const refreshProvision = useSetAtom(refreshProvisionCounter);
  const refreshProvisions = useSetAtom(refreshProvisionsCounter);

  if (provisions.state === 'loading')
    return <InlineLoading description="Loading provisions..." />;
  if (provisions.state === 'hasError')
    return (
      <InlineNotification
        kind="error"
        lowContrast
        title="Error loading provisions"
      />
    );

  const provisionsList = currentProvisions.length
    ? currentProvisions
    : provisions.state === 'hasData' &&
      provisions.data &&
      !('error' in provisions.data)
    ? provisions.data
    : [];

  const lastProvision = provisionsList.length ? provisionsList[0] : undefined;

  if (
    lastProvision &&
    (lastProvision.status === 'pending' || lastProvision.status === 'queued')
  ) {
    subscribeToChannel(
      lastProvision.notifications.channel,
      lastProvision.notifications.events[0].name,
      lastProvision.href,
      () => {
        // Refresh so that we force the atom to refresh from server even though href has not changed
        refreshProvisions();
        refreshProvision();
        setShowSuccess(true);
        onProvisionsAvailable();
      },
      () => {
        setShowError(true);
      }
    );
    return (
      <InlineLoading description={`Provisioning is ${lastProvision.status}`} />
    );
  }
  const disabledLabel = !hasImage ? (
    <>
      Provision Now unavailable.
      <br />
      Document image is missing.
    </>
  ) : (
    'Provisioning completed. Only 1 provision per document.'
  );

  return (
    <>
      {showSuccess && <ProvisionSuccess />}
      {showError && <ProvisionError />}
      {(lastProvision && lastProvision.status === 'complete') || !hasImage ? (
        <>
          <Tooltip
            open={showTooltip}
            showIcon={false}
            direction="bottom"
            align="center"
            triggerText={
              <Button
                kind="primary"
                renderIcon={SysProvision16}
                disabled
                size="sm"
                onPointerEnter={() => setShowTooltip()}
                onPointerLeave={() => setShowTooltip()}
              >
                <span>Provision Now</span>
              </Button>
            }
            focusTrap={false}
            onChange={() => {}}
          >
            {disabledLabel}
          </Tooltip>
          <ConfigureModal
            href={href}
            configurationHref={configurationHref}
            onProvisionsAvailable={onProvisionsAvailable}
          />
        </>
      ) : (
        <>
          <ConfigureModal
            href={href}
            configurationHref={configurationHref}
            onProvisionsAvailable={onProvisionsAvailable}
          />
          <Trigger
            href={href}
            onProvisionsAvailable={onProvisionsAvailable}
            setShowSuccess={setShowSuccess}
            setShowError={setShowError}
          />
        </>
      )}
    </>
  );
}

function Trigger({
  href,
  onProvisionsAvailable,
  setShowSuccess,
  setShowError,
}: {
  href: string;
  onProvisionsAvailable: () => void;
  setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { triggerProvision, generatingProvision } = useProvisionDocument(href);
  const [provisions, setProvisionList] = useAtom(provisionListAtom);
  return !generatingProvision ? (
    <>
      <Button
        disabled={!!provisions.length}
        kind="primary"
        size="sm"
        renderIcon={SysProvision16}
        onClick={() => {
          triggerProvision({
            onGeneratingProvision: (provision) => {
              setProvisionList([provision, ...provisions]);
              onProvisionsAvailable();
              setShowSuccess(true);
            },
            onError: () => {
              setShowError(true);
            },
          });
        }}
      >
        <span>Provision Now</span>
      </Button>
    </>
  ) : (
    <InlineLoading description={'Provisioning document...'} />
  );
}

function Viewer({ provision }: { provision: ProvisionResponse }) {
  const itemHref = provision.resources.items.href;
  const itemsAtom = useCompletionItems(itemHref);
  const items = useAtomValue(itemsAtom);
  const refreshProvisions = useSetAtom(refreshProvisionsCounter);
  if (items.loading) return <InlineLoading />;
  if (items.data && 'error' in items.data)
    return (
      <InlineNotification kind="error" title={items.data.error} lowContrast />
    );

  return (
    <>
      <JSONRenderer
        data={provision.content || {}}
        completionHref={provision.href}
        itemsHref={provision.resources.items.href}
        items={items.data || []}
        state={provision.state}
        documentState={provision.documentState}
        refreshProvisions={refreshProvisions}
        isCompositeProvision={isCompositeProvision(provision)}
      />
    </>
  );
}

function ProvisionTabs({
  provision,
  onPaginateParagraphs,
}: {
  provision: ProvisionResponse;
  onPaginateParagraphs: () => void;
}) {
  return (
    <Tabs>
      <Tab label="Provisions">
        <Viewer provision={provision} />
      </Tab>
      <Tab label="OCR">
        <OCRFetcher
          href={provision.resources.extraction.href}
          onPaginateParagraphs={onPaginateParagraphs}
        />
      </Tab>
    </Tabs>
  );
}

function ProvisionSwitcher({
  currentProvision,
  provisions,
  setProvision,
  onProvisionsAvailable,
}: {
  currentProvision: ProvisionResponse;
  provisions: ProvisionResponse[];
  setProvision: React.Dispatch<React.SetStateAction<ProvisionResponse>>;
  onProvisionsAvailable: () => void;
}) {
  const store = useStore();
  const [loadingReapplyDocument, setLoadingReapplyDocument] = useState(false);
  const [, reapplyDocument] = useAtom(reapplyDocumentAtom);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const index = provisions.findIndex((el) => el.id === currentProvision.id);
  const mostRecentDocumentExtractionCompletionId =
    provisions.length > 1 && provisions[1].id;
  const selectedIndex = provisions.length - index;
  const showRestructureButton =
    window.CONFIG?.featureFlags.autoprovisionerV2 &&
    currentProvision &&
    currentProvision.status === 'complete' &&
    isCompositeProvision(currentProvision);

  return (
    <div className={styles.provisionsSwitcher}>
      <h2>
        {isCompositeProvision(currentProvision)
          ? 'Provisions'
          : `LLM Output ${selectedIndex}`}
      </h2>
      <div className={styles.dropdownPanelArea} ref={ref}>
        <Button
          kind="ghost"
          className={styles.provisionButton}
          size="sm"
          onClick={() => setIsComponentVisible((current) => !current)}
        >
          <CaretDown16 />
        </Button>

        {isComponentVisible && (
          <div className={styles.dropdownPanel}>
            <ul>
              {provisions.map((el, i) => {
                const isComposite = isCompositeProvision(el);
                return (
                  <li
                    key={el.id}
                    className={classNames(
                      currentProvision.id === el.id ? styles.active : undefined,
                      isComposite ? styles.compositeProvision : undefined
                    )}
                  >
                    <Link
                      className={styles.provisionLinks}
                      onClick={() => {
                        setProvision(el);
                        store.set(currentProvisionIdAtom, el.id);
                        setIsComponentVisible(false);
                      }}
                    >
                      {currentProvision.id === el.id && <Checkmark16 />}
                      {isComposite ? (
                        'Provisions'
                      ) : (
                        <>
                          {`LLM Output ${provisions.length - i} - `}
                          <Date
                            date={el.createdAt}
                            format="M/DD/YYYY h:mm A"
                            showTime
                          />
                        </>
                      )}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <div>
        {showRestructureButton && (
          <Button
            hasIconOnly={true}
            renderIcon={Restart16}
            iconDescription="Restructure Data"
            tooltipAlignment="center"
            tooltipPosition="bottom"
            size="sm"
            kind="tertiary"
            disabled={loadingReapplyDocument}
            onClick={() => {
              setLoadingReapplyDocument(true);
              const documentExtractionCompletionId = isCompositeProvision(
                currentProvision
              )
                ? mostRecentDocumentExtractionCompletionId
                : currentProvision.id;
              reapplyDocument({
                type: 'POST',
                url: `/document_extraction_completions/${documentExtractionCompletionId}/auto_provisioned_document_process_jobs`,
                data: null,
                onSuccess: () => {
                  subscribeToChannel(
                    currentProvision.notifications.channel,
                    currentProvision.notifications.events[0].name,
                    currentProvision.href,
                    () => {
                      onProvisionsAvailable();
                      setLoadingReapplyDocument(false);
                    },
                    () => {
                      setLoadingReapplyDocument(false);
                    }
                  );
                },
                onError: (error) => {
                  console.error('Error:', error);
                },
              });
            }}
          />
        )}
      </div>
    </div>
  );
}

function OCRFetcher({
  href,
  onPaginateParagraphs,
}: {
  href: string;
  onPaginateParagraphs: () => void;
}) {
  const extractionAtom = useOCRExtractionAtom(href);
  const extraction = useAtomValue(extractionAtom);
  if (extraction.loading) return <InlineLoading />;
  if (extraction.data && 'error' in extraction.data)
    return (
      <InlineNotification
        kind="error"
        title={extraction.data.error}
        lowContrast
      />
    );

  const data = extraction.data;

  return data ? (
    <OCROutput output={data} onPaginateParagraphs={onPaginateParagraphs} />
  ) : null;
}

function OCROutput({
  output,
  onPaginateParagraphs,
}: {
  output: OCRExtractionResponse;
  onPaginateParagraphs: () => void;
}) {
  const paragraphs = output.content.paragraphs;
  const tables = output.content.tables;
  const text = output.content.text;
  const [view, setView] = useState<'paragraphs' | 'tables' | 'text'>(
    paragraphs ? 'paragraphs' : 'text'
  );
  const download = useDownloadResource(output.resources.contentDownload.href);
  return (
    <div className={styles.ocrOutputPanel}>
      <div className={styles.ocrButtonsActions}>
        {paragraphs && (
          <button
            className={classNames(
              styles.pillsBtn,
              view === 'paragraphs' ? styles.active : null
            )}
            onClick={() => setView('paragraphs')}
          >
            Text
          </button>
        )}
        {tables && (
          <button
            className={classNames(
              styles.pillsBtn,
              view === 'tables' ? styles.active : null
            )}
            onClick={() => setView('tables')}
          >
            Tables
          </button>
        )}
        <div className={styles.download}>
          <DownloadButton
            hasIconOnly
            kind="tertiary"
            iconDescription="Download OCR Result"
            tooltipAlignment="end"
            tooltipPosition="bottom"
            loading={download.generatingDownload}
            loadingText="Downloading"
            onClick={() => download.triggerDownload()}
          ></DownloadButton>
        </div>
      </div>
      {view === 'paragraphs' && paragraphs && (
        <OCRParagraphs
          paragraphs={paragraphs}
          onPaginateParagraphs={onPaginateParagraphs}
        />
      )}
      {view === 'tables' && tables && <OCRTables tables={tables} />}
      {view === 'text' && text && <TextOutput text={text} />}
    </div>
  );
}

function OCRParagraphs({
  paragraphs,
  onPaginateParagraphs,
}: {
  paragraphs: NonNullable<OCRExtractionResponse['content']['paragraphs']>;
  onPaginateParagraphs: () => void;
}) {
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 200;

  const filteredParagraphs = searchParagraphs(paragraphs, search);
  const totalPages = Math.ceil(filteredParagraphs.length / itemsPerPage);

  const getCurrentPageParagraphs = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredParagraphs.slice(startIndex, endIndex);
  };

  const currentParagraphs = getCurrentPageParagraphs();

  const pagination = (
    <Pagination
      page={currentPage}
      totalItems={filteredParagraphs.length}
      itemsPerPageText="Items"
      size="md"
      pagesUnknown
      pageSize={itemsPerPage}
      pageSizes={[itemsPerPage]}
      onChange={({ page }) => {
        onPaginateParagraphs();
        setCurrentPage(page);
      }}
    />
  );

  return (
    <div>
      <DebouncedInput
        placeholder="Search in paragraphs..."
        value={search}
        onChange={(value) => {
          if (typeof value === 'string') {
            setSearch(value);
            setCurrentPage(1); // Reset to first page on new search
          }
        }}
      />
      {filteredParagraphs.length === 0 ? (
        <Tile>
          <EmptyState
            headerText="No Results Found"
            helperText="Try adjusting your search to find what you're looking for."
          />
        </Tile>
      ) : (
        <>
          {totalPages > 1 && pagination}
          {currentParagraphs.map((el, i) => (
            <div key={i} className={styles.paragraphsWrapper}>
              <div>
                <h3>{el.role || 'Unknown'}</h3>
                <CopyButton node={el.content}></CopyButton>
              </div>
              <p dangerouslySetInnerHTML={{ __html: el.content }} />
            </div>
          ))}
          {totalPages > 1 && pagination}
        </>
      )}
    </div>
  );
}
type OCRTable = NonNullable<OCRExtractionResponse['content']['tables']>;

function OCRTables({ tables }: { tables: OCRTable }) {
  const [currentTableIndex, setCurrentTableIndex] = useState(0);

  if (!tables.length)
    return (
      <EmptyState
        IconComponent={Default}
        headerText="No Tables Found"
        helperText="There are no tables"
      />
    );

  const table = tables[currentTableIndex];

  const { rowCount, columnCount, cells } = table;

  const tableArray: OCRTable[number]['cells'][] = Array.from(
    { length: rowCount },
    () =>
      Array.from({ length: columnCount }, () => ({
        rowIndex: 0,
        columnIndex: 0,
        content: '',
      }))
  );

  cells.forEach((cell) => {
    tableArray[cell.rowIndex][cell.columnIndex] = cell;
  });

  // Determine if we have a header row
  const hasHeaderRow = tableArray[0].some(
    (cell) => cell.kind === 'columnHeader'
  );

  const rows = hasHeaderRow ? tableArray.slice(1) : tableArray;

  return (
    <>
      <div className={styles.ocrButtonsActions}>
        <legend>
          Table {currentTableIndex + 1} of {tables.length}
        </legend>
        <Button
          hasIconOnly
          kind="ghost"
          size="md"
          iconDescription="Previous Table"
          tooltipAlignment="center"
          tooltipPosition="bottom"
          renderIcon={CaretLeft16}
          onClick={() => setCurrentTableIndex(currentTableIndex - 1)}
          disabled={!currentTableIndex}
        />
        <Button
          hasIconOnly
          kind="ghost"
          size="md"
          iconDescription="Next Table"
          tooltipAlignment="center"
          tooltipPosition="bottom"
          renderIcon={CaretRight16}
          onClick={() => setCurrentTableIndex(currentTableIndex + 1)}
          disabled={currentTableIndex === tables.length - 1}
        />
      </div>
      <div className={styles.ocrTables}>
        <TableContainer>
          <Table>
            {hasHeaderRow && (
              <TableHead>
                <TableRow>
                  {tableArray[0].map((cell, index) => (
                    <TableHeader key={index}>{cell.content}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableHeader key={cellIndex}>{cell.content}</TableHeader>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function TextOutput({
  text,
}: {
  text: NonNullable<OCRExtractionResponse['content']['text']>;
}) {
  return (
    <div className={styles.paragraphsWrapper}>
      <div>
        <h3>Complete OCR</h3>
      </div>
      <p>{text}</p>
    </div>
  );
}

function ConfigureModal({
  href,
  configurationHref,
  onProvisionsAvailable,
}: {
  href: string;
  configurationHref: string;
  onProvisionsAvailable: () => void;
}) {
  const { triggerProvision, generatingProvision } = useProvisionDocument(href);
  const configurationOptionsAtom =
    useConfigurationOptionsAtom(configurationHref);
  const configuration = useAtomValue(configurationOptionsAtom);
  const [newProvisionList, setProvisionList] = useAtom(provisionListAtom);
  const provisions = useAtomValue(provisionsAtom);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const currentList = newProvisionList.length
    ? newProvisionList
    : provisions.state === 'hasData' &&
      provisions.data &&
      !('error' in provisions.data)
    ? provisions.data
    : [];

  const triggerProvisionCallback = (payload: ReturnType<typeof getPayload>) => {
    triggerProvision({
      payload,
      onGeneratingProvision: (provision) => {
        setProvisionList([provision, ...currentList]);
        onProvisionsAvailable();
        setShowSuccess(true);
      },
      onError: () => {
        setShowError(true);
      },
    });
  };
  if (configuration.loading || generatingProvision)
    return (
      <div className={styles.loadingProvision}>
        <InlineLoading />
      </div>
    );
  if (configuration.data && 'error' in configuration.data)
    return (
      <InlineNotification
        kind="error"
        title={configuration.data.error}
        lowContrast
      />
    );

  const configurationObject = configuration.data;
  if (!configurationObject) return null;

  return (
    <>
      <ConfigureModalImpl
        configurationObject={configurationObject}
        onTriggerProvision={triggerProvisionCallback}
      />
      {showSuccess && <ProvisionSuccess />}
      {showError && <ProvisionError />}
    </>
  );
}

function ConfigureModalImpl({
  configurationObject,
  onTriggerProvision,
}: {
  configurationObject: CompletionConfigurationResponse;
  onTriggerProvision: (payload: any) => void;
}) {
  const formDefaults = getFormDefaults(configurationObject);
  const [open, setOpen] = useState(false);
  const [ocr, setOcr] = useState(formDefaults.extractionConfigurations);
  const [prompt, setPrompt] = useState(formDefaults.promptConfigurations);
  const [llm, setLLM] = useState(formDefaults.completionConfigurations);
  useBodyClass('edit-document-modal-open', open);

  return (
    <>
      <Button
        hasIconOnly
        kind="primary"
        size="md"
        renderIcon={CalendarSettings16}
        iconDescription={'Configure Provision'}
        tooltipAlignment="center"
        tooltipPosition="bottom"
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        modalHeading="Configure Provision"
        className={modalStyles.configurationModal}
        onRequestClose={() => setOpen(false)}
        onRequestSubmit={() => {
          onTriggerProvision(
            getPayload({
              extractionConfiguration: ocr,
              promptConfiguration: prompt,
              completionConfiguration: llm,
            })
          );
          setOpen(false);
        }}
        secondaryButtonText="Cancel"
        primaryButtonText="Provision Now"
      >
        <div>
          <fieldset>
            <Dropdown
              titleText={<label>OCR Model</label>}
              selectedItem={ocr}
              onChange={({ selectedItem }) => {
                if (selectedItem) setOcr(selectedItem);
              }}
              itemToString={(item) =>
                `${item.provider} ${item.version}${
                  item.default ? ' (default)' : ''
                }`
              }
              id="ocr-model"
              items={configurationObject.extractionConfigurations}
              label=""
            />
          </fieldset>
          <fieldset>
            <Dropdown
              titleText={<label>Prompt Version</label>}
              selectedItem={prompt}
              onChange={({ selectedItem }) => {
                if (selectedItem) setPrompt(selectedItem);
              }}
              itemToString={(item) =>
                `${item.version}${item.default ? ' (default)' : ''}`
              }
              id="prompt-version"
              items={configurationObject.promptConfigurations}
              label=""
            />
          </fieldset>
          <fieldset>
            <Dropdown
              titleText={<label>LLM Model</label>}
              selectedItem={llm}
              onChange={({ selectedItem }) => {
                if (selectedItem) setLLM(selectedItem);
              }}
              itemToString={(item) =>
                `${item.provider} ${item.version}${
                  item.default ? ' (default)' : ''
                }`
              }
              id="llm-model"
              items={configurationObject.completionConfigurations}
              label=""
            />
          </fieldset>
        </div>
      </Modal>
    </>
  );
}

function MetadataModal({
  metadata,
  createdAt,
}: {
  metadata: ProvisionResponse['metadata'];
  createdAt: string;
}) {
  const [open, setOpen] = useState(false);
  const createAtFormatted = format(parseISO(createdAt), 'MM/dd/yyyy h:mm aaaa');

  return (
    <>
      <Button
        hasIconOnly
        kind="tertiary"
        size="md"
        renderIcon={Information16}
        iconDescription={'Information'}
        tooltipAlignment="end"
        tooltipPosition="bottom"
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        modalHeading="Information"
        passiveModal
        className={styles.informationModal}
        onRequestClose={() => setOpen(false)}
      >
        <Grid className="bx--no-gutter" fullWidth>
          <Row>
            <Column md={4}>
              <TextInput
                value={metadata.completion.createdBy || ''}
                labelText="Owner:"
                id="owner"
                readOnly
              />
            </Column>
            <Column md={4}>
              <TextInput
                value={createAtFormatted || ''}
                labelText="Date Created:"
                id="created"
                readOnly
              />
            </Column>
          </Row>

          <Row>
            <Column md={4}>
              <TextInput
                value={metadata.completion.service || ''}
                labelText="LLM Service:"
                id="llmservice"
                readOnly
              />
            </Column>
            <Column md={4}>
              <TextInput
                value={metadata.completion.version || ''}
                labelText="LLM Version:"
                id="llmversion"
                readOnly
              />
            </Column>
          </Row>

          <Row>
            <Column md={4}>
              <TextInput
                value={metadata.ocr.service || ''}
                labelText="OCR Service:"
                id="ocrservice"
                readOnly
              />
            </Column>
            <Column md={4}>
              <TextInput
                value={metadata.ocr.version || ''}
                labelText="OCR Version:"
                id="ocrversion"
                readOnly
              />
            </Column>
          </Row>

          <Row>
            <Column md={8}>
              <TextInput
                value={metadata.completion.prompt || ''}
                labelText="Version Prompt:"
                id="versionprompt"
                readOnly
              />
            </Column>
          </Row>
        </Grid>
      </Modal>
    </>
  );
}

function searchParagraphs(
  paragraphs: NonNullable<OCRExtractionResponse['content']['paragraphs']>,
  searchString: string
) {
  if (!searchString) return paragraphs;
  const stringMatch = (str: string) =>
    searchString !== '' &&
    str.toLowerCase().includes(searchString.toLowerCase());

  function highlightString(str: string): string {
    if (searchString === '') return str;
    const regex = new RegExp(`(${searchString})`, 'gi');
    return str.replace(regex, `<span class="${styles.highlight}">$1</span>`);
  }

  return paragraphs
    .map((el) => {
      return stringMatch(el.content)
        ? { ...el, content: highlightString(el.content) }
        : null;
    })
    .filter(
      (
        el
      ): el is NonNullable<
        OCRExtractionResponse['content']['paragraphs']
      >[number] => el !== null
    );
}

const EventsContent = ({ provision }: { provision: ProvisionResponse }) => {
  return (
    <EventsPanel
      header={''}
      title="Provision"
      helperText="Context allows you to share information about a provision."
      eventsHref={provision.resources.events.href}
      commentsHref={provision.resources.comments.href}
    />
  );
};

function ProvisionSuccess() {
  return (
    <SuccessToast
      message="Document Provisioned"
      subtitle="You have successfully provisioned the document"
    />
  );
}

function ProvisionError() {
  return <ErrorToast message="Problem Provisioning Document" />;
}
