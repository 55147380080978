import { Checkmark20 } from '@carbon/icons-react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { DataTableSkeleton } from 'carbon-components-react';
import { Date } from 'components/date';
import { HiddenColumns } from 'components/table/hidden-columns';
import { TableActions } from 'components/table/table-actions';
import { useColumnVisibility } from 'lib/hooks/useColumnVisibilityAtom';
import {
  DECIMAL_FORMATTER,
  FORMATTER,
  PERCENT_FORMATTER_TWO_DECIMALS,
} from 'lib/ui';
import { useCallback, useMemo, useRef, useState, Fragment } from 'react';
import { capitalizeFirstLetter } from 'utils/strings';
import { LoadPackage } from '../page';
import style from './units.module.scss';

import { DndContext, DragMoveEvent, closestCenter } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  ColumnPinningState,
  Row,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DebouncedInput } from 'components/debounced-input';
import { Link } from 'components/link';
import { MIN_WIDTH } from 'components/table/column-actions';
import { ColumnFilter } from 'components/table/column-filter';
import { useColumnFilter } from 'lib/hooks/useColumnFilter';
import { useColumnSizing } from 'lib/hooks/useColumnSizing';
import { useColumnState } from 'lib/hooks/useColumnState';
import usePinnedColumnReordering from 'lib/hooks/usePinnedColumnReordering';
import plur from 'plur';
import { PackageEvaluationSlotAllocationsResponse } from 'types/packages-api/responses';
import { DragCell, DragHeader } from './draggable-table-components';
import { LoadSlotAllocations } from './load-slot-allocations';
import {
  getInitialColumnOrder,
  getPinningStyles,
  handleDragEnd,
  handleDragMove,
} from './utils';

const toFixed = (value: number, fractionDigits: number) => {
  if (typeof value === 'number') {
    return value.toFixed(fractionDigits);
  }

  return `--`;
};

const columnHelper =
  createColumnHelper<PackageEvaluationSlotAllocationsResponse>();

const columns = [
  columnHelper.accessor((row) => row.asset.name, {
    id: 'assetName',
    header: 'Asset',
    size: 130,
    cell: ({ row }) => {
      const value = row.original.asset;
      return value.salesforceHref ? (
        <a
          style={{ cursor: 'pointer' }}
          rel="noreferrer"
          href={value.salesforceHref}
          target="_blank"
        >
          {value.name || 'Empty Name'}
        </a>
      ) : (
        value.name || 'Empty Name'
      );
    },
    footer: () => {
      return 'Totals';
    },
  }),
  columnHelper.accessor((row) => row.subtract.name, {
    id: 'subtractName',
    header: 'Subtract',
    size: 150,
    cell: ({ row }) => {
      const value = row.original.subtract;
      return value.salesforceHref ? (
        <a
          style={{ cursor: 'pointer' }}
          rel="noreferrer"
          href={value.salesforceHref}
          target="_blank"
        >
          {value.name || 'Empty Name'}
        </a>
      ) : (
        value.name || 'Empty Name'
      );
    },
  }),
  columnHelper.accessor(
    (row) => {
      return `${row.developmentArea.name || 'Empty Name'} `;
    },
    {
      id: 'unitEvaluationName',
      header: 'Unit Evaluation',
      size: 215,
      cell: ({ row }) => {
        const evaluation = row.original.developmentAreaEvaluation;
        const name = `${row.getValue('unitEvaluationName')} (${
          evaluation ? evaluation.id : 'Error running eval'
        })`;
        return evaluation?.href ? (
          <Link to={evaluation.href} target="_blank">
            {name}
          </Link>
        ) : (
          name
        );
      },
    }
  ),
  columnHelper.accessor('slotCategory', {
    id: 'slotCategory',
    header: 'Slot Category',
    size: 160,
    cell: (row) => {
      const value = row.getValue();
      return value
        ? value === 'permit'
          ? 'Permit'
          : value.toUpperCase()
        : '--';
    },
  }),
  columnHelper.accessor((row) => row.well?.uwi || '--', {
    id: 'uwi',
    header: 'UWI',
    size: 130,
    sortingFn: (rowA, rowB) => {
      const a = rowA.original.well?.uwi || '--';
      const b = rowB.original.well?.uwi || '--';
      return a > b ? 1 : a < b ? -1 : 0;
    },
    cell: ({ row }) => {
      const value = row.original.well;
      return !row.getIsGrouped() && value?.href ? (
        <Link to={value.href} target="_blank">
          {value.uwi || '--'}
        </Link>
      ) : (
        value?.uwi || '--'
      );
    },
  }),
  columnHelper.accessor('formationName', {
    id: 'formationName',
    header: 'Formation Name',
    size: 180,
  }),
  columnHelper.accessor('slotNumber', {
    id: 'slotNumber',
    header: 'Slot #',
    size: 120,
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('basin', {
    id: 'basin',
    header: 'Basin',
    size: 120,
  }),
  columnHelper.accessor('tier', {
    id: 'tier',
    header: 'Tier',
    size: 120,
  }),
  columnHelper.accessor('developmentAreaGrossAcreage', {
    id: 'developmentAreaGrossAcreage',
    header: 'Development Area Gross Acreage',
    size: 275,
    cell: (row) => {
      const value = row.getValue();
      return value ? DECIMAL_FORMATTER.format(value) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor((row) => (row.filedWellOnly ? 'FWO' : '--'), {
    id: 'filedWellOnly',
    header: 'Filed Well Only',
    size: 170,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('volumesScalingFactor', {
    id: 'volumesScalingFactor',
    header: 'Volumes Scaling Factor',
    size: 220,
    cell: (row) => {
      const value = row.getValue();
      return PERCENT_FORMATTER_TWO_DECIMALS.format(value);
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('slotVolumeSource', {
    id: 'slotVolumeSource',
    header: 'Forecast ID',
    size: 460,
  }),
  columnHelper.accessor('forecastStatus', {
    id: 'forecastStatus',
    header: 'Forecast Status',
    size: 170,
    cell: (row) => {
      const value = row.getValue();
      return value
        ? value === 'type-curve'
          ? 'type curve'
          : value.toUpperCase()
        : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('typeCurveStatus', {
    id: 'typeCurveStatus',
    header: 'Type Curve Status',
    size: 185,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('typeCurveLateralLengthFt', {
    id: 'typeCurveLateralLengthFt',
    header: 'Type Curve Lateral Length (ft)',
    size: 255,
    cell: (row) => {
      const v = row.getValue();
      if (typeof v !== 'number') return '--';
      return DECIMAL_FORMATTER.format(v);
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('typeCurveHorizontalWellSpacingFt', {
    id: 'typeCurveHorizontalWellSpacingFt',
    header: 'Type Curve Hz Spacing (ft)',
    size: 255,
    cell: (row) => {
      const v = row.getValue();
      if (typeof v !== 'number') return '--';
      return DECIMAL_FORMATTER.format(v);
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('oilEurMbbl', {
    id: 'oilEurMbbl',
    header: 'Oil EUR (Mbbl)',
    size: 170,
    cell: (row) => {
      const v = row.getValue();
      if (typeof v !== 'number') return '--';
      return DECIMAL_FORMATTER.format(v);
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('gasEurMmcf', {
    id: 'gasEurMmcf',
    header: 'Gas EUR (MMcf)',
    size: 175,
    cell: (row) => {
      const v = row.getValue();
      if (typeof v !== 'number') return '--';
      return DECIMAL_FORMATTER.format(v);
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('formationDepth', {
    id: 'formationDepth',
    header: 'Formation Depth (ft)',
    size: 200,
    cell: (row) => {
      const v = row.getValue();
      if (typeof v !== 'number') return '--';
      return DECIMAL_FORMATTER.format(v);
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('slotDepth', {
    id: 'slotDepth',
    header: 'Slot Depth (ft)',
    size: 185,
    cell: (row) => {
      const v = row.getValue();
      if (typeof v !== 'number') return '--';
      return DECIMAL_FORMATTER.format(v);
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor((row) => row.well?.name || '--', {
    id: 'wellName',
    header: 'Well Name',
    size: 230,
  }),
  columnHelper.accessor('operator.name', {
    id: 'operatorName',
    header: 'Operator Name',
    size: 190,
  }),
  columnHelper.accessor('well', {
    id: 'wellPermitDate',
    header: 'Permit Date',
    cell: (row) => {
      const v = row.getValue()?.permitDate;
      return v ? <Date date={v} /> : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('spudDate', {
    id: 'spudDate',
    header: 'Spud Date',
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? <Date date={v} /> : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('well', {
    id: 'completionDate',
    header: 'Completion Date',
    size: 180,
    cell: (row) => {
      const v = row.getValue()?.completionDate;
      return v ? <Date date={v} /> : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('firstProductionDate', {
    id: 'firstProductionDate',
    header: 'First Production Date',
    size: 210,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? <Date date={v} /> : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('shutInDate', {
    id: 'shutInDate',
    header: 'Shut-In Date',
    size: 210,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? <Date date={v} /> : '--';
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('irr', {
    id: 'irr',
    header: 'IRR',
    size: 290,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? PERCENT_FORMATTER_TWO_DECIMALS.format(v) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('hurdleRate', {
    id: 'hurdleRate',
    header: 'Hurdle Rate',
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? PERCENT_FORMATTER_TWO_DECIMALS.format(v) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('isExcluded', {
    id: 'isExcluded',
    header: 'Excluded',
    cell: (row) => {
      const v = row.getValue();
      return v ? <Checkmark20 /> : '--';
    },
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('subtractGrossAcreage', {
    id: 'subtractGrossAcreage',
    header: 'Subtract Gross Acreage',
    size: 220,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? toFixed(v, 2) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('allocationRatio', {
    id: 'allocationRatio',
    header: 'Allocation Ratio',
    size: 190,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? toFixed(v, 7) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('allocatedSubtractGrossAcreage', {
    id: 'allocatedSubtractGrossAcreage',
    header: 'Allocated Subtract Gross Acreage',
    size: 275,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? toFixed(v, 7) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('allocatedSubtractNetAcreage', {
    id: 'allocatedSubtractNetAcreage',
    header: 'Allocated Subtract Net Acreage',
    size: 270,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? toFixed(v, 2) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('netAcreageUnits', {
    id: 'netAcreageUnits',
    header: 'Net Acreage Units',
    size: 180,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('instrumentType', {
    id: 'instrumentType',
    header: 'Asset Type',
    size: 150,
    cell: (row) => {
      const v = row.getValue();
      return capitalizeFirstLetter(v);
    },
  }),
  columnHelper.accessor('interestType', {
    id: 'interesType',
    header: 'Interest Type',
    size: 160,
    cell: (row) => {
      const v = row.getValue();
      return capitalizeFirstLetter(v);
    },
  }),
  columnHelper.accessor('unitType', {
    id: 'unitType',
    header: 'Unit Type',
    size: 140,
  }),
  columnHelper.accessor('royaltyRate', {
    id: 'royaltyRate',
    header: 'Royalty Rate',
    size: 160,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? PERCENT_FORMATTER_TWO_DECIMALS.format(v) : '--';
    },
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('overridingRoyaltyRate', {
    id: 'overridingRoyaltyRate',
    header: 'ORRI',
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? PERCENT_FORMATTER_TWO_DECIMALS.format(v) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('netRoyaltyAcres', {
    id: 'netRoyaltyAcres',
    header: 'Net Royalty Acres',
    size: 190,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? toFixed(v, 2) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('netMineralAcres', {
    id: 'netMineralAcres',
    header: 'Net Mineral Acres',
    size: 190,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? toFixed(v, 2) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('netRevenueInterest', {
    id: 'netRevenueInterest',
    header: 'Net Revenue Interest',
    size: 190,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? toFixed(v, 8) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('netWorkingInterest', {
    id: 'netWorkingInterest',
    header: 'Net Working Interest',
    size: 210,
    cell: (row) => {
      const v = row.getValue();
      return v ? toFixed(v, 8) : '--';
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('targetPriceInDollars', {
    id: 'targetPriceInDollars',
    header: 'Target Price ($)',
    size: 180,
    cell: (row) => {
      const v = row.getValue();
      return FORMATTER.format(v);
    },
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
    footer: ({ table }) => {
      return FORMATTER.format(
        table
          .getFilteredRowModel()
          .rows.reduce(
            (total, row) => total + row.original.targetPriceInDollars,
            0
          )
      );
    },
  }),
  columnHelper.accessor('targetPriceInDollarsPerNRA', {
    id: 'targetPriceInDollarsPerNra',
    header: 'Target Price ($/NRA)',
    size: 210,
    cell: (row) => {
      const v = row.getValue();
      return v ? FORMATTER.format(v) : '--';
    },
    aggregationFn: (_columnId, leafRows) => {
      const accumulators = leafRows.reduce(
        (acc, el) => {
          acc.price += el.original.targetPriceInDollars;
          acc.nra += el.original.netRoyaltyAcres || 0;
          return acc;
        },
        { price: 0, nra: 0 }
      );
      const { price, nra } = accumulators;
      if (nra > 0) {
        return price / nra;
      }
      return null;
    },
    meta: {
      filterVariant: 'range',
    },
    enableGrouping: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('targetPriceInDollarsPerNMA', {
    id: 'targetPriceInDollarsPerNma',
    header: 'Target Price ($/NMA)',
    size: 190,
    cell: (row) => {
      const v = row.getValue();
      return v !== null ? FORMATTER.format(v) : '$0';
    },
    aggregationFn: (_columnId, leafRows) => {
      const accumulators = leafRows.reduce(
        (acc, el) => {
          acc.price += el.original.targetPriceInDollars;
          acc.nma += el.original.netMineralAcres || 0;
          return acc;
        },
        { price: 0, nma: 0 }
      );
      const { price, nma } = accumulators;
      if (nma > 0) {
        return price / nma;
      }
      return null;
    },
    enableGrouping: false,
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('maxPriceInDollars', {
    id: 'maxPriceInDollars',
    header: 'Max Price ($)',
    size: 165,
    cell: (row) => {
      const v = row.getValue();
      return FORMATTER.format(v);
    },
    enableGrouping: false,
    enableGlobalFilter: false,
    footer: ({ table }) => {
      return FORMATTER.format(
        table
          .getFilteredRowModel()
          .rows.reduce(
            (total, row) => total + row.original.maxPriceInDollars,
            0
          )
      );
    },
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('maxPriceInDollarsPerNRA', {
    id: 'maxPriceInDollarsPerNra',
    header: 'Max Price ($/NRA)',
    size: 190,
    cell: (row) => {
      const v = row.getValue();
      return v ? FORMATTER.format(v) : '--';
    },
    aggregationFn: (_columnId, leafRows) => {
      const accumulators = leafRows.reduce(
        (acc, el) => {
          acc.price += el.original.maxPriceInDollars;
          acc.nra += el.original.netRoyaltyAcres || 0;
          return acc;
        },
        { price: 0, nra: 0 }
      );
      const { price, nra } = accumulators;
      if (nra > 0) {
        return price / nra;
      }
      return null;
    },
    enableGrouping: false,
    enableGlobalFilter: false,
    meta: {
      filterVariant: 'range',
    },
  }),
  columnHelper.accessor('maxPriceInDollarsPerNMA', {
    enableGrouping: false,
    id: 'maxPriceInDollarsPerNma',
    header: 'Max Price ($/NMA)',
    size: 190,
    enableGlobalFilter: false,
    cell: (row) => {
      const v = row.getValue();
      return v ? FORMATTER.format(v) : '--';
    },
    aggregationFn: (_columnId, leafRows) => {
      const accumulators = leafRows.reduce(
        (acc, el) => {
          acc.price += el.original.maxPriceInDollars;
          acc.nma += el.original.netMineralAcres || 0;
          return acc;
        },
        { price: 0, nma: 0 }
      );
      const { price, nma } = accumulators;
      if (nma > 0) {
        return price / nma;
      }
      return null;
    },
    meta: {
      filterVariant: 'range',
    },
  }),
];

const SlotsAllocations = ({ href }: { href: string }) => {
  return (
    <LoadSlotAllocations
      slotHref={href}
      renderSlots={(allocations) => {
        return <SlotAllocationsTable data={allocations || []} />;
      }}
    />
  );
};

const SlotAllocationsTable = ({
  data,
}: {
  data: PackageEvaluationSlotAllocationsResponse[];
}) => {
  const tableId = 'package-slot-allocations-table';
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnSizing, setColumnSizing] = useColumnSizing(tableId);
  const [columnVisibility, setColumnVisibility] = useColumnVisibility(tableId);

  const {
    columnFilters,
    setColumnFilters,
    isColumnFilterActive,
    setIsColumnFilterActive,
  } = useColumnFilter(tableId);

  const initialColumnPinning: ColumnPinningState = {
    left: ['assetName', 'subtractName', 'unitEvaluationName'],
  };

  const initialColumnOrder = useMemo(
    () => getInitialColumnOrder(columns),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { columnOrder, setColumnOrder, columnPinning, setColumnPinning } =
    useColumnState({
      tableId: tableId,
      initialColumnOrder,
      initialColumnPinning,
    });

  const hoveredColumnIdRef = useRef<string | null>(null);

  usePinnedColumnReordering(columnPinning, columnOrder, setColumnOrder);

  const onDragMove = useCallback(
    (e: DragMoveEvent) => {
      handleDragMove(e, hoveredColumnIdRef);
    },
    [hoveredColumnIdRef]
  );

  const table = useReactTable({
    data: data || [],
    columnResizeMode: 'onChange',
    columns,
    state: {
      sorting,
      globalFilter,
      columnSizing,
      columnVisibility,
      columnFilters,
      columnOrder,
      columnPinning,
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    defaultColumn: {
      size: 150, // starting column size
      minSize: MIN_WIDTH,
    },
    initialState: {
      columnPinning: {
        left: ['assetName', 'subtractName', 'unitEvaluationName'],
      },
      columnOrder: initialColumnOrder,
    },
    meta: {
      reset: {
        resetCustomizations: () => {
          setIsColumnFilterActive(false);
        },
      },
    },
  });

  const { rows } = table.getRowModel();

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48,
    overscan: 20,
  });

  if (!data) {
    return (
      <DataTableSkeleton
        showHeader={false}
        columnCount={columns.length}
        rowCount={10}
        showToolbar={false}
      />
    );
  }

  const isColumunVisible = table.getVisibleLeafColumns().length > 0;

  return (
    <LoadPackage
      render={() => (
        <>
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={(e) =>
              handleDragEnd(
                e,
                columnPinning,
                columnOrder,
                setColumnOrder,
                setColumnPinning,
                hoveredColumnIdRef
              )
            }
            modifiers={[restrictToHorizontalAxis]}
            onDragMove={onDragMove}
          >
            <div ref={parentRef} className={style.stackTableArea}>
              <div className={style.tableContext}>
                <div className={style.stackSearchArea}>
                  <DebouncedInput
                    className={style.stackSearchField}
                    value={globalFilter ?? ''}
                    onChange={(value) => setGlobalFilter(String(value).trim())}
                    placeholder="Search columns..."
                  />
                </div>
                <TableActions
                  table={table}
                  isColumnFilterActive={isColumnFilterActive}
                  setIsColumnFilterActive={(isColumnFilterActive) =>
                    setIsColumnFilterActive(isColumnFilterActive)
                  }
                ></TableActions>
              </div>
              {isColumunVisible ? (
                <table className={style.stackTable}>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Fragment key={headerGroup.id}>
                        <tr>
                          <SortableContext
                            items={columnOrder}
                            strategy={horizontalListSortingStrategy}
                          >
                            {headerGroup.headers.map((header) => (
                              <DragHeader
                                key={header.id}
                                header={header}
                                hoveredColumnIdRef={hoveredColumnIdRef}
                              />
                            ))}
                          </SortableContext>
                        </tr>
                        {isColumnFilterActive ? (
                          <tr>
                            <ColumnFilter
                              pinStyleGenerator={getPinningStyles}
                              headers={headerGroup.headers}
                            ></ColumnFilter>
                          </tr>
                        ) : null}
                      </Fragment>
                    ))}
                  </thead>
                  <tbody
                    style={{ minHeight: `${virtualizer.getTotalSize()}px` }}
                  >
                    {virtualizer.getVirtualItems().map((virtualRow, index) => {
                      const row = rows[
                        virtualRow.index
                      ] as Row<PackageEvaluationSlotAllocationsResponse>;
                      return (
                        <tr
                          key={row.id}
                          style={{
                            minHeight: `${virtualRow.size}px`,
                            transform: `translateY(${
                              virtualRow.start - index * virtualRow.size
                            }px)`,
                          }}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <SortableContext
                              key={cell.id}
                              items={columnOrder}
                              strategy={horizontalListSortingStrategy}
                            >
                              <DragCell
                                key={cell.id}
                                cell={cell}
                                row={row}
                                hoveredColumnIdRef={hoveredColumnIdRef}
                              />
                            </SortableContext>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    {table.getFooterGroups().map((footerGroup) => (
                      <tr key={footerGroup.id}>
                        {footerGroup.headers.map((header) => (
                          <th
                            style={{ ...getPinningStyles(header.column) }}
                            key={header.id}
                            colSpan={header.colSpan}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.footer,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </tfoot>
                </table>
              ) : (
                <HiddenColumns className={style.hiddenColumn}></HiddenColumns>
              )}
            </div>
            {isColumunVisible ? (
              <div className={style.totalRowCount}>
                <label className={style.totalRows}>
                  {DECIMAL_FORMATTER.format(
                    table.getFilteredRowModel().rows.length
                  )}
                </label>
                {plur('row', table.getFilteredRowModel().rows.length)}
              </div>
            ) : null}
          </DndContext>
        </>
      )}
    />
  );
};

export { SlotsAllocations };
