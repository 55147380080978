import { DirectUpload } from '@rails/activestorage';
import { createMutationAtom } from 'atoms/create-resource-atom';
import { getResources } from 'atoms/root';
import { atom } from 'jotai';
import { createResourceAtom } from 'lib/atoms';
import request from 'lib/request';
import { useMemo } from 'react';
import {
  WellRelatedUnitsResponse,
  WellFeaturesResponse,
  WellFormationResponse,
  WellProductionForecastResponse,
  WellProductionRecordResponse,
  WellsResponse,
  WellSupportingFileResponse,
  WellVersionsResponse,
  ProductionRecordsVersionsResponse,
  ProductionForecastsVersionsResponse,
} from 'types/api-responses';
import { SupportingFile } from './types';
import { atomWithStorage } from 'jotai/utils';

const MAX_FILE_SIZE = 500 * 1024 * 1024;

const useWellAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellsResponse>(href), [href]);

// Fetch production forecasts. Create a mutation atom with performs not get operations.
const useProductionForecastsAtom = () =>
  useMemo(
    () =>
      createMutationAtom<WellProductionForecastResponse[], 'wellAnalysis'>(),
    []
  );

// Fetch production records. Create a mutation atom with performs not get operations.
const useProductionRecordsAtom = () =>
  useMemo(
    () => createMutationAtom<WellProductionRecordResponse[], 'wellAnalysis'>(),
    []
  );

// Fetch production forecasts versions. Triggers the fetch on mount
const useProductionForecastsVersionsAtom = (href: string) => {
  return useMemo(() => {
    const innerForecastMutationAtom = createMutationAtom<
      ProductionForecastsVersionsResponse,
      'versions'
    >();
    const forecastAtom = atom(
      (get) => get(innerForecastMutationAtom),
      (get, set) => {
        set(innerForecastMutationAtom, {
          type: 'POST',
          url: href,
          data: null,
        });
      }
    );

    forecastAtom.onMount = (set) => {
      set();
    };
    return forecastAtom;
  }, [href]);
};

// Fetch production records versions. Triggers the fetch on mount
const useProductionRecordsVersionsAtom = (href: string) => {
  return useMemo(() => {
    const innerRecordMutationAtom = createMutationAtom<
      ProductionRecordsVersionsResponse,
      'versions'
    >();
    const recordAtom = atom(
      (get) => get(innerRecordMutationAtom),
      (get, set) => {
        set(innerRecordMutationAtom, {
          type: 'POST',
          url: href,
          data: null,
        });
      }
    );

    recordAtom.onMount = (set) => {
      set();
    };
    return recordAtom;
  }, [href]);
};

const useWellFormationAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellFormationResponse>(href), [href]);

const useWellFeaturesAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellFeaturesResponse>(href), [href]);

const useSupportingFilesAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellSupportingFileResponse[]>(href), [href]);

const useUnitTableAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellRelatedUnitsResponse[]>(href), [href]);

// atom to extract well page versions
const useWellVersionsAtom = (href: string) =>
  useMemo(() => createResourceAtom<WellVersionsResponse>(href), [href]);

const fileBaseAtom = atom<SupportingFile>({
  status: 'edit',
  name: undefined,
  file: undefined,
  invalid: false,
});
const fileAtom = atom<SupportingFile, [File | undefined], void>(
  (get) => get(fileBaseAtom),
  (get, set, file) => {
    if (!file) {
      set(fileBaseAtom, { status: 'edit' });
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      set(fileBaseAtom, { status: 'edit', invalid: true });
      return;
    }

    const resources = getResources(get);

    const url = resources?.directUploads.href;
    if (!url) return;
    const directUpload = new DirectUpload(file, url);
    set(fileBaseAtom, { status: 'edit' });
    directUpload.create((error, blob) => {
      if (error) {
        set(fileBaseAtom, { status: 'edit' });
      } else {
        set(fileBaseAtom, {
          status: 'complete',
          file: blob.signed_id,
          name: file.name,
          invalid: false,
        });
      }
    });
  }
);

const useDeleteFileAtom = (getSupportingDocs: () => Promise<void>) =>
  useMemo(
    () =>
      atom<null, [string], void>(null, async (get, set, url) => {
        await request.deleteReq(url, undefined, new AbortController());
        getSupportingDocs();
      }),
    [getSupportingDocs]
  );

const createSupportingFileAtom = createMutationAtom<
  null,
  'wellSupportingFile'
>();

const importFileModalOpenAtom = atom(false);

// atom to control the visibility of the well scoped unit layer
const wellScopedUnitLayerVisibility = atomWithStorage(
  'well-scoped-unit-layer',
  true
);

export {
  createSupportingFileAtom,
  fileAtom,
  useDeleteFileAtom,
  useProductionForecastsAtom,
  useProductionRecordsAtom,
  useProductionRecordsVersionsAtom,
  useProductionForecastsVersionsAtom,
  useSupportingFilesAtom,
  useWellAtom,
  useWellFeaturesAtom,
  useWellFormationAtom,
  importFileModalOpenAtom,
  useUnitTableAtom,
  useWellVersionsAtom,
  wellScopedUnitLayerVisibility,
};
