import {
  ArrowsVertical16,
  Column16,
  Filter16,
  FilterRemove16,
  ResetAlt16,
  SortAscending16,
  SortDescending16,
} from '@carbon/icons-react';
import style from './table-actions.module.scss';
import { OverflowMenu, Button, Modal, Tooltip } from 'carbon-components-react';
import { useState } from 'react';
import { Toggle } from 'components/toggle/toggle';
import { DebouncedInput } from 'components/debounced-input';
import classNames from 'classnames';
import { RowData, Table } from '@tanstack/react-table';
import plur from 'plur';

declare module '@tanstack/react-table' {
  // allows us to define custom properties for our columns
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    reset: {
      resetCustomizations: () => void;
    };
  }
}

interface Props<T> {
  table: Table<T>;
  isColumnFilterActive: boolean;
  setIsColumnFilterActive: (active: boolean) => void;
}

export function TableActions<T>({
  table,
  isColumnFilterActive,
  setIsColumnFilterActive,
}: Props<T>) {
  const columns = table.getAllColumns();
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC' | null>(null);
  const [filterBy, setFilterBy] = useState('');
  const [showResetWarning, setShowResetWarning] = useState(false);

  const orderedColumns = sortOrder
    ? columns.sort((a, b) => {
        const aHeader = a.columnDef.header?.toString() ?? '';
        const bHeader = b.columnDef.header?.toString() ?? '';
        return aHeader.localeCompare(bHeader) * (sortOrder === 'ASC' ? 1 : -1);
      })
    : columns;

  const filteredColumns = orderedColumns.filter((col) => {
    const colHeader = col.columnDef.header?.toString() ?? '';
    return colHeader.toLowerCase().startsWith(filterBy);
  });

  const hiddenColumnCount = orderedColumns.filter(
    (col) => !col.getIsVisible()
  ).length;
  const visibleColumnCount = orderedColumns.length - hiddenColumnCount;

  const handleShowAll = () => {
    columns.forEach((col) => {
      col.getToggleVisibilityHandler()({ target: { checked: true } });
    });
  };

  const handleHideAll = () => {
    columns.forEach((col) => {
      col.getToggleVisibilityHandler()({ target: { checked: false } });
    });
  };

  const handleReset = () => {
    table.resetColumnFilters(true);
    table.resetColumnSizing(true);
    table.resetColumnVisibility(true);
    table.resetColumnOrder();
    table.resetColumnPinning();
    table.options.meta?.reset.resetCustomizations();
    setShowResetWarning(false);
  };

  const renderSortIcon = () => {
    if (sortOrder === 'ASC') {
      return <SortDescending16 className={style.sortIcon}></SortDescending16>;
    } else if (sortOrder === 'DESC') {
      return <SortAscending16 className={style.sortIcon}></SortAscending16>;
    } else {
      return <ArrowsVertical16 className={style.sortIcon}></ArrowsVertical16>;
    }
  };

  const toggleColumnFilter = () => {
    setIsColumnFilterActive(!isColumnFilterActive);
  };
  const [showTooltipFilter, setShowTooltipFilter] = useState(false);
  const [showTooltipMenu, setShowTooltipMenu] = useState(false);
  const [showTooltipReset, setShowTooltipReset] = useState(false);

  return (
    <>
      <Modal
        open={showResetWarning}
        modalHeading="Reset table to default"
        danger
        size="xs"
        primaryButtonText="Reset to default"
        secondaryButtonText="Cancel"
        className={style.resetTableModal}
        onRequestClose={() => setShowResetWarning(false)}
        onRequestSubmit={() => handleReset()}
      >
        <p>Are you sure you want to reset the table to its default settings?</p>
        <p>This action will revert all changes and cannot be undone.</p>
      </Modal>
      <div className={style.tableActions}>
        <Tooltip
          open={showTooltipFilter}
          showIcon={false}
          direction="top"
          align="center"
          tabIndex={0}
          focusTrap={false}
          onChange={() => {}}
          triggerText={
            <Button
              hasIconOnly
              onClick={toggleColumnFilter}
              kind="ghost"
              size="lg"
              iconDescription="Show/Hide filters"
              onPointerEnter={() => setShowTooltipFilter(true)}
              onPointerLeave={() => setShowTooltipFilter(false)}
              renderIcon={isColumnFilterActive ? FilterRemove16 : Filter16}
              className={isColumnFilterActive ? style.activeButton : null}
            />
          }
        >
          Show/Hide filters
        </Tooltip>
        {hiddenColumnCount ? (
          <span className={style.columnsCount}>
            {`${hiddenColumnCount} ${plur('column', hiddenColumnCount)} hidden`}
          </span>
        ) : null}

        <Tooltip
          open={showTooltipMenu}
          showIcon={false}
          direction="top"
          align="center"
          focusTrap={false}
          onChange={() => {}}
          triggerText={
            <OverflowMenu
              className={style.tableOverflowMenu}
              renderIcon={Column16}
              flipped
              size="lg"
              onPointerEnter={() => setShowTooltipMenu(true)}
              onPointerLeave={() => setShowTooltipMenu(false)}
            >
              <>
                <div className={style.menu}>
                  <p className={style.title}>Columns ({columns.length})</p>
                  <div className={style.scrollableArea}>
                    <div className={style.shortcut}>
                      <button
                        className={classNames(
                          visibleColumnCount
                            ? style.ghostButton
                            : style.ghostButtonDisabled
                        )}
                        onClick={() => handleHideAll()}
                      >
                        Hide All
                      </button>
                      <button
                        onClick={() => handleShowAll()}
                        className={classNames(
                          hiddenColumnCount
                            ? style.ghostButton
                            : style.ghostButtonDisabled
                        )}
                      >
                        Show All
                      </button>
                      <span
                        onClick={() =>
                          setSortOrder((sortOrder) => {
                            return sortOrder === 'ASC' ? 'DESC' : 'ASC';
                          })
                        }
                      >
                        {renderSortIcon()}
                      </span>
                    </div>

                    <div className={style.searchArea}>
                      <DebouncedInput
                        value={''}
                        onChange={(text) => {
                          setFilterBy(text.toString().toLocaleLowerCase());
                        }}
                        placeholder="Search columns..."
                      />
                    </div>
                    {filteredColumns.map((column) => (
                      <div key={column.id} className={style.columnItem}>
                        <p className={style.columnName} key={column.id}>
                          {column.columnDef.header?.toString() ?? ''}
                        </p>
                        <div>
                          <Toggle
                            isActive={column.getIsVisible()}
                            handleToggle={(visibility) =>
                              column.getToggleVisibilityHandler()({
                                target: { checked: visibility },
                              })
                            }
                          ></Toggle>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            </OverflowMenu>
          }
        >
          Show/Hide columns
        </Tooltip>
        <Tooltip
          open={showTooltipReset}
          showIcon={false}
          direction="top"
          align="center"
          tabIndex={0}
          focusTrap={false}
          onChange={() => {}}
          triggerText={
            <Button
              hasIconOnly
              renderIcon={ResetAlt16}
              kind="ghost"
              size="lg"
              iconDescription="Reset table to default"
              onPointerEnter={() => setShowTooltipReset(true)}
              onPointerLeave={() => setShowTooltipReset(false)}
              onClick={() => setShowResetWarning(true)}
            />
          }
        >
          Reset table to default
        </Tooltip>
      </div>
    </>
  );
}
