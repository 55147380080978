import { Breadcrumb, BreadcrumbItem } from 'carbon-components-react';
import { FC, ReactNode, VFC, useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import style from './page-header.module.scss';

interface Props {
  breadcrumbs: [string, ReactNode][];
  title: ReactNode;
  actions: ReactNode;
  aside?: ReactNode;
  subtitle?: string;
  titleDetail?: ReactNode;
  iconComponent?: ReactNode;
  stickyHeader?: boolean;
  attributes?: ReactNode;
  referrer?: JSX.Element | null;
}

const PageHeader: FC<Props> = ({
  breadcrumbs,
  children,
  title,
  actions,
  subtitle,
  titleDetail,
  aside,
  iconComponent,
  stickyHeader,
  attributes,
  referrer,
}) => {
  const [scroll, setScroll] = useState(false);
  const shouldUpdateScrollState = useRef(true);
  useEffect(() => {
    if (stickyHeader) {
      const handler = () => {
        if (shouldUpdateScrollState.current && window.scrollY !== 0) {
          setScroll(true);
          document.body.classList.add('sticky-header');
          shouldUpdateScrollState.current = false;
        }

        if (document.body && window.scrollY === 0) {
          setScroll(false);
          document.body.classList.remove('sticky-header');
          shouldUpdateScrollState.current = true;
        }
      };
      window.addEventListener('scroll', handler);
      return () => {
        window.removeEventListener('scroll', handler);
        document.body.classList.remove('sticky-header');
      };
    }
  }, [stickyHeader]);

  return (
    <header
      className={classNames(style.header, scroll ? style.scrolled : null)}
    >
      {breadcrumbs.length !== 0 && (
        <div className={style.breadcrumbs}>
          <Breadcrumb noTrailingSlash>
            {breadcrumbs.map(([location, text], index) => {
              const isCurrent = index === breadcrumbs.length - 1;
              return (
                <Crumb key={location} {...{ location, text, isCurrent }} />
              );
            })}
          </Breadcrumb>
          {referrer}
        </div>
      )}
      <div
        className={classNames(
          style.content,
          attributes ? style.attributesHeader : null
        )}
      >
        <div>
          <h2>{subtitle}</h2>
          <div className={style.title}>
            <div className={style.titleColumn}>
              {typeof title === 'string' ? (
                <h1>
                  {iconComponent && (
                    <span className={style.icon}>{iconComponent}</span>
                  )}
                  {title}
                </h1>
              ) : (
                title
              )}

              {titleDetail}
            </div>
            {attributes && <div>{attributes}</div>}
            {aside && <aside>{aside}</aside>}
          </div>
        </div>
        {actions && <div className={style.actions}>{actions}</div>}
      </div>
      {children && <div className={style.children}>{children}</div>}
    </header>
  );
};

interface CrumbProps {
  location: string;
  text: ReactNode;
  isCurrent: boolean;
}

const Crumb: VFC<CrumbProps> = ({ location, text, isCurrent }) => {
  return (
    <BreadcrumbItem isCurrentPage={isCurrent}>
      <NavLink exact to={location}>
        {text}
      </NavLink>
    </BreadcrumbItem>
  );
};

export default PageHeader;
