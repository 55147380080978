import {
  Column,
  Grid,
  InlineNotification,
  Row,
  Tab,
  Tabs,
  Tile,
} from 'carbon-components-react';
import { Date } from 'components/date';
import { EmptyState } from 'components/empty-state';
import { ProductionGraph } from 'components/production-graph';
import NoDataSvg from 'images/srp-illustration-data.svg';
import NoRecordSvg from 'images/srp-clipboard.svg';
import SrpComment from 'images/srp-comment.svg';
import { useAtom, useAtomValue } from 'jotai';
import {
  useProductionForecastsAtom,
  useProductionForecastsVersionsAtom,
  useProductionRecordsAtom,
  useProductionRecordsVersionsAtom,
} from './atoms';

import { SkeletonTextRows } from 'components/skeleton-text-rows';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  ProductionForecastsVersionsResponse,
  ProductionRecordsVersionsResponse,
  WellProductionForecastResponse,
  WellProductionRecordResponse,
} from 'types/api-responses';
import style from './production.module.scss';
import { DropdownItem, VersionDropdown } from './version-dropdown';
import { debounce } from 'lodash';
import ErrorToast from 'components/error-toast';
import {
  TypeLayout,
  TypeLayoutBody,
  TypeLayoutCell,
  TypeLayoutRow,
} from '@carbon/ibm-security';
import { Field } from './field';
import { format } from 'lib/ui';
import { parseUTCdateTime } from 'utils/dates';

export type Legend = 'gas' | 'water' | 'liquid';

export const defaultSelectedLegend: Legend[] = ['liquid', 'water', 'gas'];

// Format the numeric value to a string with two decimal places or return undefined if the value is null or undefined
function formatValue(
  value: number | undefined | null,
  unit?: string,
  fallback = '-'
): string {
  if (value !== null && value !== undefined) {
    return `${format.number(value)} ${unit || ''}`;
  }
  return fallback;
}

// Get the version name based on the version ID
function getVersionNameById(
  versionId: string,
  versions:
    | ProductionRecordsVersionsResponse
    | ProductionForecastsVersionsResponse
): string {
  const match = versions.find((v) => v.versionId === versionId);
  return match?.versionName || '';
}

// Generate the version label based on the version name, version ID, begin time, and end time
function generateVersionLabel(
  versionName: string | undefined,
  versionId: string,
  beginTime: string,
  endTime: string | null,
  showTimeStamp: boolean
): string {
  const formattedBeginTime = parseUTCdateTime(beginTime, showTimeStamp);
  const formattedEndTime = endTime
    ? parseUTCdateTime(endTime, showTimeStamp)
    : null;

  return `${versionName} ${
    versionId === 'current' ? 'Current' : ''
  } (${formattedBeginTime}${formattedEndTime ? ` - ${formattedEndTime}` : ''})`;
}

// Extract the graph data from the response
function extractGraphData(
  data: Array<
    (WellProductionForecastResponse | WellProductionRecordResponse) & {
      versionName: string;
    }
  >
): Array<{
  version: string;
  dates: string[];
  liquid: number[];
  water: number[];
  gas: number[];
  versionId: string;
}> {
  return data.map((item) => {
    return {
      version: item.versionName,
      versionId: item.versionId,
      dates: item.dates || [],
      liquid: item.liquid || [],
      water: item.water || [],
      gas: item.gas || [],
    };
  });
}

// Example dropDown object use dto display dropdown items:
// {
//   id: "2024-10-25T19:02:00Z",
//   versionID: "2024-10-25T19:02:00Z",
//   label: "Version 2 (10/24/2024 - 10/26/2024)",
//  }
//   // Dropdown label combining versionName, versionDisplayTime, beginTime, and endTime.
//   // Dates are formatted using Moment.js to MM/DD/YYYY.
// Formatting steps:
// 1. Parse `beginTime` and `endTime` from UTC format.
// 2. Use Moment.js to convert the date into the desired format (e.g., MM/DD/YYYY).
// 3. Concatenate the formatted dates with `versionName` and `versionDisplayTime`

function createDropdownItems(
  versions:
    | ProductionForecastsVersionsResponse
    | ProductionRecordsVersionsResponse,
  showEndTime: boolean = true,
  showTimeStamp: boolean = false
): DropdownItem[] {
  return versions.map((version) => ({
    id: version.versionId,
    label: generateVersionLabel(
      version.versionName,
      version.versionId,
      version.beginTime,
      showEndTime ? version.endTime : null,
      showTimeStamp
    ),
    versionId: version.versionId,
  }));
}

const renderStatisticRow = (
  label: string,
  value: string | number | JSX.Element
) => (
  <TypeLayoutRow key={label}>
    <TypeLayoutCell className={style.colLeft}>{label}</TypeLayoutCell>
    <TypeLayoutCell className={style.colRight}>{value}</TypeLayoutCell>
  </TypeLayoutRow>
);

export function Forecast({
  versions,
  productionForecastsHref,
}: {
  versions: ProductionForecastsVersionsResponse;
  productionForecastsHref: string;
}) {
  // first paraam is the versions, second param indicates if the end time should be shown,
  // third param indicates if the timestamp should be shown
  const dropdownItems = createDropdownItems(versions, false, true);
  // Initialize state with the index of the 'Current' version or fallback to 0

  // Fetch production forecast data based on the selected index
  const productionForecastsAtom = useProductionForecastsAtom();
  const [forecasts, setForecasts] = useAtom(productionForecastsAtom);
  const [selectedVersionIds, setSelectedVersionIds] = useState<string[]>([]);
  const [selectedLegendVersion, setSelectedLegendVersion] = useState<
    string | undefined
  >();

  // Initialize state for failure toast
  const [failureToast, setFailureToast] = useState(false);
  const [selectedLegend, setSelectedLegend] = useState<Legend[]>(
    defaultSelectedLegend
  );

  // Reset to the 'Current' version if available
  const handleResetTrigger = () => {
    const versionId = 'current';
    setSelectedVersionIds([versionId]);
    setSelectedLegendVersion(versionId);
  };

  // debounce the setProductionForecast function to avoid multiple calls
  const debouncedSetProductionForecast = useMemo(
    () =>
      debounce((payload) => {
        setForecasts(payload);
      }, 800),
    [setForecasts] // Ensure dependencies are tracked correctly
  );

  // Immediate API call when transitioning selectedVersionIds from 0 to 1
  useEffect(() => {
    if (selectedVersionIds.length > 0) {
      // Handle debounced API call for multiple or subsequent selections
      debouncedSetProductionForecast({
        type: 'POST',
        url: productionForecastsHref,
        data: {
          versionIds: selectedVersionIds,
        },
        onError: () => setFailureToast(true),
      });
    } else {
      // Cancel debounced API call when no versions are selected
      debouncedSetProductionForecast.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersionIds, debouncedSetProductionForecast]);

  // Handle dropdown change when a version is selected
  const handleDropdownChange = (versionIds: string[]) => {
    setSelectedVersionIds(versionIds);
    setSelectedLegendVersion(versionIds.length > 1 ? undefined : versionIds[0]);
  };

  // Render the production forecast graph
  const renderContent = () => {
    if (selectedVersionIds.length === 0) {
      return (
        <Tile>
          <EmptyState
            headerText="No data to display"
            icon={NoDataSvg}
            helperText="Select a version from the dropdown in the top-left to view your graph."
          />
        </Tile>
      );
    }
    // If the forecast is loading or there is no data, show a skeleton loader
    if (forecasts.loading) {
      return <SkeletonTextRows rows={10} />;
    }

    // If there is an error in the forecast data, show an error notification
    if (forecasts.result?.type !== 'success') {
      return (
        <InlineNotification
          kind="error"
          title={'Some error occurred while fetching production forecast'}
          lowContrast
        />
      );
    }

    // If there is production data, render the grap
    if (forecasts.result?.type === 'success' && forecasts.result.data.length) {
      // Process the data to include the version name by getting the version name from the versions array
      const forecastData = forecasts.result.data.map((item) => ({
        ...item,
        versionName: getVersionNameById(item.versionId, versions),
      }));

      // Pass only forecastData
      const graphData = extractGraphData(forecastData);
      return (
        <ProductionGraph
          graphData={graphData}
          className={style.graph}
          selectedLegendVersion={selectedLegendVersion}
          setSelectedLegendVersion={setSelectedLegendVersion}
          staggeredSymbol={true}
          selectedLegend={selectedLegend}
          setSelectedLegend={setSelectedLegend}
        />
      );
    }

    // If there is no production data, show an empty state
    return (
      <Tile>
        <EmptyState
          headerText="No Data Available"
          icon={NoDataSvg}
          helperText="Currently Insights doesn’t have any production data."
        />
      </Tile>
    );
  };

  // eslint-disable-next-line
  const renderStatistics = () => {
    if (selectedLegendVersion === undefined) {
      return (
        <div className={style.statistics}>
          <section>
            <EmptyState
              headerText="No data available"
              icon={NoRecordSvg}
              helperText="Please select a version below the chart to view details."
            />
          </section>
        </div>
      );
    }

    if (forecasts.loading) {
      return <SkeletonTextRows rows={10} />;
    }

    if (forecasts.result?.type === 'success') {
      const resultProductionForecast = forecasts.result.data.find(
        (forecast) => forecast.versionId === selectedLegendVersion
      );

      if (resultProductionForecast) {
        // Fallback to mock data if real data isn't available
        const {
          engineer,
          generatedOn,
          totalLiquid,
          recoveredTotalLiquid,
          remainingTotalLiquid,
          totalGas,
          notes,
          recoveredTotalGas,
          remainingTotalGas,
        } = resultProductionForecast;

        // Helper function to format numeric values

        // Map forecast data to label-value pairs
        const forecastDetails = [
          {
            label: 'Liquid EUR',
            value: formatValue(totalLiquid, 'bbl'),
          },
          {
            label: 'Liquid Recovered',
            value: formatValue(recoveredTotalLiquid, 'bbl'),
          },
          {
            label: 'Liquid Remaining',
            value: formatValue(remainingTotalLiquid, 'bbl'),
          },
          {
            label: 'Gas EUR',
            value: formatValue(totalGas, 'mcf'),
          },
          {
            label: 'Gas Recovered',
            value: formatValue(recoveredTotalGas, 'mcf'),
          },
          {
            label: 'Gas Remaining',
            value: formatValue(remainingTotalGas, 'mcf'),
          },
          {
            label: 'Date of Decline',
            value: generatedOn ? <Date date={generatedOn} /> : '-',
          },
          {
            label: 'Engineer',
            value: engineer || '-',
          },
        ];

        return (
          <div className={style.statistics}>
            <section>
              <h4 className={style.sectionSubheader}>PDP Forecast Details</h4>
              <TypeLayout className={style.table}>
                <TypeLayoutBody>
                  {forecastDetails.map(({ label, value }) =>
                    renderStatisticRow(label, value)
                  )}
                </TypeLayoutBody>
              </TypeLayout>

              <div className={style.detailsFields}>
                <Field truncate={true} label="Comments:" value={notes}></Field>
              </div>
            </section>
          </div>
        );
      } else null;
    }
  };

  return (
    <Grid className="bx--no-gutter" fullWidth>
      {/* Render Empty State in its own column */}
      {versions.length === 0 && (
        <Row>
          <Column lg={16} className={style.statistics}>
            <section>
              <EmptyState
                headerText="No PDP forecast data available"
                icon={SrpComment}
                helperText="Contact petro team for support."
              />
            </section>
          </Column>
        </Row>
      )}
      {/* Render main content if versions exist */}
      {versions.length > 0 && (
        <Row>
          <Column lg={16}>
            {failureToast && (
              <ErrorToast message="Data for selected version failed to load" />
            )}
            {/* Version dropdown component */}
            <div className={style.controls}>
              <VersionDropdown
                dropdownItems={dropdownItems}
                onReset={handleResetTrigger}
                onChange={handleDropdownChange}
                selectedVersionIds={selectedVersionIds}
              />
            </div>
            <div className={style.content}>{renderContent()}</div>
          </Column>
          {/* <Column lg={6}>{renderStatistics()}</Column> */}
        </Row>
      )}
    </Grid>
  );
}

export function Record({
  versions,
  productionRecordsHref,
}: {
  versions: ProductionRecordsVersionsResponse;
  productionRecordsHref: string;
}) {
  const dropdownItems = createDropdownItems(versions);

  // Fetch production record data based on the selected version
  const productionRecordsAtom = useProductionRecordsAtom();
  const [productionRecords, setProductionRecords] = useAtom(
    productionRecordsAtom
  );
  const [selectedLegendVersion, setSelectedLegendVersion] = useState<
    string | undefined
  >();

  const [selectedVersionIds, setSelectedVersionIds] = useState<string[]>([]);
  // failure toast state
  const [failureToast, setFailureToast] = useState(false);

  const [selectedLegend, setSelectedLegend] = useState<Legend[]>(
    defaultSelectedLegend
  );

  const debouncedSetProductionRecord = useMemo(
    () =>
      debounce((payload) => {
        setProductionRecords(payload);
      }, 800),
    [setProductionRecords] // Ensure dependencies are tracked correctly
  );

  useEffect(() => {
    if (selectedVersionIds.length > 0) {
      // Handle debounced API call for multiple or subsequent selections
      debouncedSetProductionRecord({
        type: 'POST',
        url: productionRecordsHref,
        data: {
          versionIds: selectedVersionIds,
        },
        onError: () => setFailureToast(true),
      });
    } else {
      // Cancel debounced API call when no versions are selected
      debouncedSetProductionRecord.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersionIds, debouncedSetProductionRecord]);

  // Handle dropdown change when a version is selected
  const handleDropdownChange = (versionIds: string[]) => {
    setSelectedVersionIds(versionIds);
    setSelectedLegendVersion(versionIds.length > 1 ? undefined : versionIds[0]);
  };

  // Reset to the 'Current' version if available
  const handleResetTrigger = () => {
    const versionId = 'current';
    setSelectedVersionIds([versionId]);
    setSelectedLegendVersion(versionId);
  };

  // Render the production record graph
  const renderContent = () => {
    if (selectedVersionIds.length === 0) {
      return (
        <Tile>
          <EmptyState
            headerText="No data to display"
            icon={NoDataSvg}
            helperText="Select a version from the dropdown in the top-left to view your graph."
          />
        </Tile>
      );
    }
    // If the production record is loading or there is no data, show a skeleton loader
    if (productionRecords.loading) {
      return <SkeletonTextRows rows={10} />;
    }

    // If there is an error in the production record data, show an error notification
    if (
      productionRecords.result?.type !== 'success' &&
      productionRecords.result !== null
    ) {
      return (
        <InlineNotification
          kind="error"
          title={'Some error occurred while fetching production record'}
          lowContrast
        />
      );
    }

    // If there is production data, render the graph
    if (
      productionRecords.result?.type === 'success' &&
      productionRecords.result.data.length &&
      selectedVersionIds.length
    ) {
      // Process the data to include the version name by getting the version name from the versions array
      const productionRecordData = productionRecords.result.data.map(
        (item) => ({
          ...item,
          versionName: getVersionNameById(item.versionId, versions),
        })
      );

      // Pass only productionRecordData
      const graphData = extractGraphData(productionRecordData);
      return (
        <ProductionGraph
          graphData={graphData}
          className={style.graph}
          selectedLegendVersion={selectedLegendVersion}
          setSelectedLegendVersion={setSelectedLegendVersion}
          selectedLegend={selectedLegend}
          setSelectedLegend={setSelectedLegend}
        />
      );
    }

    return (
      <Tile>
        <EmptyState
          headerText="No Data Available"
          icon={NoDataSvg}
          helperText="Currently Insights doesn’t have any production data."
        />
      </Tile>
    );
  };

  // eslint-disable-next-line
  const renderStatistics = () => {
    if (selectedLegendVersion === undefined) {
      return (
        <div className={style.statistics}>
          <section>
            <EmptyState
              headerText="No data available"
              icon={NoRecordSvg}
              helperText="Please select a version below the chart to view details."
            />
          </section>
        </div>
      );
    } else if (productionRecords.loading) {
      return <SkeletonTextRows rows={10} />;
    } else if (productionRecords.result?.type === 'success') {
      const selectedProdRecord = productionRecords.result.data.find(
        (record) => record.versionId === selectedLegendVersion
      );
      return selectedProdRecord ? (
        <div className={style.statistics}>
          <section>
            <h4 className={style.header}>Cumulative Production</h4>
            <TypeLayout>
              <TypeLayoutBody>
                {/** Below fields checks for nullish values and displays '-' if true, otherwise displays the value */}
                {[
                  {
                    label: 'Liquid',
                    value: formatValue(selectedProdRecord.totalLiquid, 'bbl'),
                  },
                  {
                    label: 'Gas',
                    value: formatValue(selectedProdRecord.totalGas, 'mcf'),
                  },
                  {
                    label: 'Water',
                    value: formatValue(selectedProdRecord.totalWater, 'bbl'),
                  },
                  {
                    label: 'Last Production Month',
                    value: selectedProdRecord.lastProductionMonth ? (
                      <Date
                        date={selectedProdRecord.lastProductionMonth}
                        format="M/D/YYYY"
                      />
                    ) : (
                      '-'
                    ),
                  },
                  {
                    label: 'Production Last Updated',
                    value: selectedProdRecord.lastProductionDate ? (
                      <Date
                        date={selectedProdRecord.lastProductionDate}
                        format="M/D/YYYY"
                      />
                    ) : (
                      '-'
                    ),
                  },
                  {
                    label: 'First to Last Production',
                    value: formatValue(
                      selectedProdRecord.monthsFromFirstToLastProduction,
                      'Months'
                    ),
                  },
                  {
                    label: 'Reported',
                    value: formatValue(
                      selectedProdRecord.monthsReported,
                      'Months'
                    ),
                  },
                ].map(({ label, value }) => renderStatisticRow(label, value))}
              </TypeLayoutBody>
            </TypeLayout>
          </section>
        </div>
      ) : null;
    }
  };

  return (
    <Grid className="bx--no-gutter" fullWidth>
      <Row>
        <Column lg={16}>
          {versions.length === 0 ? (
            // Render Empty State if no versions are available
            <div className={style.statistics}>
              <section>
                <EmptyState
                  headerText="No production data available"
                  icon={SrpComment}
                  helperText="Contact petro team for support."
                />
              </section>
            </div>
          ) : (
            <>
              {failureToast && (
                <ErrorToast message="Data for selected version failed to load" />
              )}
              <div className={style.controls}>
                {/* Version dropdown component */}
                <VersionDropdown
                  dropdownItems={dropdownItems}
                  onReset={handleResetTrigger}
                  onChange={handleDropdownChange}
                  selectedVersionIds={selectedVersionIds}
                />
              </div>
              <div className={style.content}>{renderContent()}</div>
            </>
          )}
        </Column>
        {/* <Column lg={4}>{renderStatistics()}</Column> */}
      </Row>
    </Grid>
  );
}

function WellAnalysisImpl({
  productionForecastsHref,
  productionForecastsVersionsHref,
  productionRecordsHref,
  productionRecordsVersionsHref,
}: {
  productionRecordsHref: string;
  productionRecordsVersionsHref: string;
  productionForecastsHref: string;
  productionForecastsVersionsHref: string;
}) {
  const productionRecordsVersionsAtom = useProductionRecordsVersionsAtom(
    productionRecordsVersionsHref
  );
  const productionRecordsVersions = useAtomValue(productionRecordsVersionsAtom);

  const productionForecastsVersionsAtom = useProductionForecastsVersionsAtom(
    productionForecastsVersionsHref
  );
  const productionForecastsVersions = useAtomValue(
    productionForecastsVersionsAtom
  );

  const recordTabRender = () => {
    if (productionRecordsVersions.loading) {
      return <SkeletonTextRows rows={10} />;
    } else if (productionRecordsVersions.result?.type !== 'success') {
      return (
        <InlineNotification
          kind="error"
          title={
            'Some error occurred while fetching production record versions'
          }
          lowContrast
        />
      );
    } else if (
      productionRecordsVersions.result.type === 'success' &&
      productionRecordsVersions.result.data
    ) {
      return (
        <Record
          versions={productionRecordsVersions.result.data}
          productionRecordsHref={productionRecordsHref}
        ></Record>
      );
    } else {
      return null;
    }
  };

  const forecastTabRender = () => {
    if (productionForecastsVersions.loading) {
      return <SkeletonTextRows rows={10} />;
    } else if (productionForecastsVersions.result?.type !== 'success') {
      return (
        <InlineNotification
          kind="error"
          title={
            'Some error occurred while fetching production forecast versions'
          }
          lowContrast
        />
      );
    } else if (
      productionForecastsVersions.result.type === 'success' &&
      productionForecastsVersions.result.data
    ) {
      return (
        <Forecast
          versions={productionForecastsVersions.result.data}
          productionForecastsHref={productionForecastsHref}
        ></Forecast>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Tabs className={style.variableWidth}>
        <Tab label="Production" key="production-tab">
          {recordTabRender()}
        </Tab>
        <Tab label="PDP Forecast" key="declines-tab">
          {forecastTabRender()}
        </Tab>
      </Tabs>
    </>
  );
}

export const WellAnalysis = memo(WellAnalysisImpl);
